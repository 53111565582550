import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material.module';
import { RouterModule } from '@angular/router';
import { ItemRoutingModule } from './item-routing.module';

import { ItemListComponent } from './item-list/item-list.component';
import { CategoryComponent } from '../category/category.component';
import { ItemCreateComponent } from './item-create/item-create.component';
import { ItemDetailComponent } from './item-detail/item-detail.component';



@NgModule({
    declarations: [
        ItemListComponent, 
        CategoryComponent, 
        ItemCreateComponent, 
        ItemDetailComponent
    ],
    imports: [
        CommonModule,
        ItemRoutingModule, 
        AngularMaterialModule, 
        RouterModule, 
        ReactiveFormsModule, 
        FormsModule, 
    ]
})
export class ItemModule { }
