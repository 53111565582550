import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { OrderService } from 'src/app/core/services/order.service';
import { Order } from '../order.model'

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit, OnDestroy {

    // プルダウン項目
    years: number[] = [];
    months: number[] = [1,2,3,4,5,6,7,8,9,10,11,12];
    dates: number[] = [];

    // プルダウン選択値
    year: number;
    month: number;
    date: number;

    isLoading = false;
    displayedColumns: string[];
    orders: Order[] = [];
    order_count;
    dataSource;

    private ordersSub: Subscription;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        public orderService: OrderService
    ) { }

    ngOnInit(): void {
        this.getYMDPulldown();
        this.getDatesPulldown();

        this.onGetOrders();

    }

    getYMDPulldown() {
        const maxYear = new Date().getFullYear() + 1;
        const minYear = 2018;
        this.year = new Date().getFullYear()
        this.month = new Date().getMonth() + 1;
        this.date = new Date().getDate();

        for (let i = minYear; i <= maxYear; i++) {
            this.years.push(i);
        }
    }

    getDatesPulldown() {
        // うるう年判定
        const isLeapYear = year => (year % 4 === 0) && (year % 100 !== 0) || (year % 400 === 0);
        const datesOfFebruary = isLeapYear(this.year) ? 29 : 28;

        // 月別最終日
        const datesOfYear = [31, datesOfFebruary, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // 月別日付作成
        const monthIndex = this.month - 1;
        this.dates = [];
        for (let i = 1; i <= datesOfYear[monthIndex]; i++) {
            this.dates.push(i);
        }        
    }

    onGetOrders() {
        // this.isLoading = true;
        this.orderService.getOrders();

        this.ordersSub = this.orderService.getOrdersUpdateListener().subscribe((orders: Order[]) => {
            this.orders = orders;

            this.order_count = Object.keys(orders).length;

            this.dataSource = new MatTableDataSource(orders);
            this.dataSource.paginator = this.paginator;

            this.isLoading = false;

            this.displayedColumns = [
                'customer_cd',
                'company_name',
                'customer_name',
                'expected_shipping_day',
                'shipped_day',
                'all_returned_date',
                'subtotal',
            ];
            // this.displayedColumns = [
            //     'customer_cd',
            //     'company_name',
            //     'customer_name',
            //     'expected_shipping_day',
            //     'shipped_day',
            //     'all_returned_date',
            //     'subtotal',
            //     'icon'
            // ];
            console.log(orders);
        });
    }

    ngOnDestroy() {
        this.ordersSub.unsubscribe();
    }
}
