<section>
    <div class="body">
        <h1 class="section-title">会社名閲覧</h1>
        <mat-card>
            <mat-spinner *ngIf="isLoading"></mat-spinner>

            <div class="card__heading">
                <div class="registerd_state" [class.unregistered]="unregistered">
                    {{ registerdState }}
                </div>
            </div>
            <div class="box">
                <h2 class="box__title">会社名情報</h2>
                <table class="box__table">
                    <tr>
                        <th>法人・個人</th>
                        <td>{{ post?.is_company }}</td>
                    </tr>
                    <tr>
                        <th>会社CD</th>
                        <td>{{ post?.cd }}</td>
                    </tr>
                    <tr>
                        <th>会社名／個人名</th>
                        <td>{{ post?.name }}</td>
                    </tr>
                    <tr>
                        <th>会社名／個人名（かな）</th>
                        <td>{{ post?.name_kana }}</td>
                    </tr>
                    <tr>
                        <th>支店／拠点名</th>
                        <td>{{ post?.branch_name }}</td>
                    </tr>
                    <tr>
                        <th>郵便番号</th>
                        <td>{{ post?.zip }}</td>
                    </tr>
                    <tr>
                        <th>都道府県</th>
                        <td>{{ post?.prefecture }}{{ post?.address }} {{ post?.building }} {{ post?.room_no }}</td>
                    </tr>
                    <tr>
                        <th>電話番号</th>
                        <td>{{ post?.tel }}</td>
                    </tr>
                    <tr>
                        <th>FAX</th>
                        <td>{{ post?.fax }}</td>
                    </tr>
                    <tr>
                        <th>締日</th>
                        <td>{{ post?.closing_day }}</td>
                    </tr>
                    <tr>
                        <th>請求書送付方法</th>
                        <td>{{ post?.bill }}</td>
                    </tr>
                    <tr>
                        <th>備考</th>
                        <td>{{ post?.note }}</td>
                    </tr>

                    <tr>
                        <th>登録日</th>
                        <td>{{ post?.registered_at | date : 'yyyy年MM月dd日' }}</td>
                    </tr>
                    <tr>
                        <th>更新日</th>
                        <td>{{ post?.updated_at | date : 'yyyy年MM月dd日' }}</td>
                    </tr>
                    <tr>
                        <th>削除日</th>
                        <td>{{ post?.deleted_at | date : 'yyyy年MM月dd日' }}</td>
                    </tr>
                </table>

                <ng-container *ngFor="let companyCustomer of companyCustomers; let i = index">
                    <h2 class="box__title">登録者情報 {{ i + 1 }}</h2>
                    <table class="box__table">
                        <tr>
                            <th>登録者CD</th>
                            <td>
                                <a [routerLink]="['/customer-edit/', companyCustomer.id]">{{ companyCustomer.cd }}</a>
                            </td>
                        </tr>
                        <tr>
                            <th>登録者名</th>
                            <td>{{ companyCustomer.name }}</td>
                        </tr>
                        <tr>
                            <th>登録者名かな</th>
                            <td>{{ companyCustomer.name_kana }}</td>
                        </tr>
                        <tr>
                            <th>部署名</th>
                            <td>{{ companyCustomer.department }}</td>
                        </tr>
                        <tr>
                            <th>肩書</th>
                            <td>{{ companyCustomer.position }}</td>
                        </tr>
                        <tr>
                            <th>携帯電話</th>
                            <td>{{ companyCustomer.mobile_phone }}</td>
                        </tr>
                        <tr>
                            <th>メールアドレス</th>
                            <td>{{ companyCustomer.email }}</td>
                        </tr>
                        <tr>
                            <th>備考</th>
                            <td>{{ companyCustomer.note }}</td>
                        </tr>
                        <tr>
                            <th>登録日</th>
                            <td>{{ companyCustomer.registered_at | date : 'yyyy年MM月dd日' }}</td>
                        </tr>
                        <tr>
                            <th>更新日</th>
                            <td>{{ companyCustomer.updated_at | date : 'yyyy年MM月dd日' }}</td>
                        </tr>
                        <tr>
                            <th>削除日</th>
                            <td>{{ companyCustomer.deleted_at | date : 'yyyy年MM月dd日' }}</td>
                        </tr>
                        <tr>
                            <th>ファイル</th>
                            <td>
                                <a href="{{ companyCustomer.image_path }}">{{ companyCustomer.file_name }}</a>
                            </td>
                        </tr>
                    </table>
                </ng-container>
            </div>

            <div class="submit-flex">
                <button mat-raised-button class="submit-button" color="basic" (click)="goBack()">戻る</button>
            </div>
        </mat-card>
    </div>
</section>
