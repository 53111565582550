<div class="body">
    <h1 class="section-title">注文登録</h1>
    <!-- <h1 class="section-title" *ngIf="mode == 'create'; else editH1">注文登録</h1> -->
    <!-- <ng-template #editH1>
        <h1 class="section-title">注文更新</h1>
    </ng-template> -->
    
    <form [formGroup]="form" (submit)="onSaveOrderPost()" *ngIf="!isLoading">
        <section>
            <mat-spinner *ngIf="isLoading"></mat-spinner>

            <!-- 注文ステータス -->
            <div class="status">
                <div class="status__item">
                    <div class="status__number fulfilled">1</div>
                    <p class="status__text">出荷前</p>
                </div>
                <div class="status__separator"></div>
                <div class="status__item">
                    <div class="status__number">2</div>
                    <p class="status__text">出荷中</p>
                </div>
                <div class="status__separator"></div>
                <div class="status__item">
                    <div class="status__number">3</div>
                    <p class="status__text">発送連絡済</p>
                </div>
                <div class="status__separator"></div>
                <div class="status__item">
                    <div class="status__number">4</div>
                    <p class="status__text">検品済</p>
                </div>
                <div class="status__separator"></div>
                <div class="status__item">
                    <div class="status__number">5</div>
                    <p class="status__text">返却連絡済</p>
                </div>
                <div class="status__separator"></div>
                <div class="status__item">
                    <div class="status__number">6</div>
                    <p class="status__text">入金確認済</p>
                </div>
                <div class="status__separator"></div>
                <div class="status__item">
                    <div class="status__number">7</div>
                    <p class="status__text">領収書発送済</p>
                </div>
            </div>

            <div class="card">
                <h2 class="card__title">基本情報</h2>
                <div class="card__content">
                    <div class="card__line">
                        <mat-form-field appearance="fill" class="per20">
                            <mat-label>注文番号</mat-label>
                            <input matInput type="text" formControlName="order_number" tabindex="1" readonly />
                        </mat-form-field>
                        <div class="customer_name">
                            <mat-form-field appearance="fill">
                                <mat-label>顧客名</mat-label>
                                <input matInput type="text" formControlName="customer_name" tabindex="2" readonly />
                                <!-- <mat-error *ngIf="form.get('companyName').invalid">顧客名は必須です</mat-error> -->
                            </mat-form-field>
                            <button class="customer_button" mat-button tabindex="3">
                                <mat-icon [inline]="true">apartment</mat-icon>
                                <!-- <mat-icon [inline]="true" (click)="openDialog()">apartment</mat-icon> -->
                            </button>
                        </div>
                        <mat-form-field appearance="fill" class="per20">
                            <mat-label>登録者CD</mat-label>
                            <input matInput type="text" formControlName="customer_cd" tabindex="4" />
                        </mat-form-field>
                    </div>
                    <div class="card__line">
                        <mat-form-field appearance="fill" class="per20">
                            <mat-label>注文日</mat-label>
                            <input matInput [matDatepicker]="picker_orderd_date" formControlName="orderd_date" tabindex="5" />
                            <mat-datepicker-toggle matSuffix [for]="picker_orderd_date"></mat-datepicker-toggle>
                            <mat-datepicker #picker_orderd_date></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="per20">
                            <mat-label>出荷予定日</mat-label>
                            <input matInput [matDatepicker]="picker_expected_shipping_day" formControlName="expected_shipping_day" tabindex="6" />
                            <mat-datepicker-toggle matSuffix [for]="picker_expected_shipping_day"></mat-datepicker-toggle>
                            <mat-datepicker #picker_expected_shipping_day></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="per20">
                            <mat-label>基本返却日</mat-label>
                            <input matInput [matDatepicker]="picker_returning_scheduled_date" formControlName="returning_scheduled_date" tabindex="7" />
                            <mat-datepicker-toggle matSuffix [for]="picker_returning_scheduled_date"></mat-datepicker-toggle>
                            <mat-datepicker #picker_returning_scheduled_date></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="per20">
                            <mat-label>全品返却日</mat-label>
                            <input matInput [matDatepicker]="picker_all_returned_date" formControlName="all_returned_date" tabindex="8" />
                            <mat-datepicker-toggle matSuffix [for]="picker_all_returned_date"></mat-datepicker-toggle>
                            <mat-datepicker #picker_all_returned_date></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="per20">
                            <mat-label>注文方法</mat-label>
                            <mat-select formControlName="how_to_order" tabindex="9">
                                <mat-option *ngFor="let result of way_of_order" [value]="result.value">
                                    {{ result.viewValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="card__line">
                        <mat-form-field appearance="fill" class="per20">
                            <mat-label>発送方法</mat-label>
                            <mat-select formControlName="how_to_ship" tabindex="10">
                                <mat-option *ngFor="let result of way_to_ship" [value]="result.value">
                                    {{ result.viewValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="per20">
                            <mat-label>登録担当者</mat-label>
                            <mat-select formControlName="user_id" tabindex="11">
                                <mat-option *ngFor="let result of users" [value]="result.id">
                                    {{ result.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="order_note">
                            <mat-label>社内備考（伝票非表示）</mat-label>
                            <input matInput type="text" formControlName="note" tabindex="12" />
                        </mat-form-field>
                    </div>
                    <div class="card__line">
                        <mat-form-field appearance="fill" class="per100">
                            <mat-label>備考（仕事名等）</mat-label>
                            <input matInput type="text" formControlName="print_note" tabindex="13" />
                        </mat-form-field>
                    </div>
                    <div class="card__line">
                        <mat-form-field appearance="fill" class="per100">
                            <mat-label>注文受付時メッセージ</mat-label>
                            <input matInput type="text" formControlName="message" tabindex="14" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </section>
        
        
        <section>
            <div class="submit">
                <div class="submit__left">
                    <a mat-raised-button class="submit__submit-button" color="basic" (click)="goBack()">キャンセル</a>
                </div>
                <div class="submit__right">
                    <a mat-raised-button class="submit__submit-button" color="basic">印刷</a>
                    <a mat-raised-button class="submit__submit-button" color="basic">発送完了メール</a>
                    <a mat-raised-button class="submit__submit-button" color="basic">検品完了メール</a>
                    <a mat-raised-button class="submit__submit-button" color="basic">復活</a>
                    <button mat-raised-button class="submit__submit-button" color="accent" type="submit">
                        <ng-container *ngIf="mode == 'create'; else edit">登録</ng-container>
                        <ng-template #edit>
                            <div class="deleted_bg">更新</div>
                        </ng-template>
                    </button>
                    <!-- <p class="submit__revive-button">復活させる</p> -->
                </div>
                <!-- <p class="revive-button" *ngIf="unregistered" (click)="onRestore()">復活させる</p> -->
            </div>
        </section>
    </form>
</div>



