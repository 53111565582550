import { CmsService } from '../../core/services/cms.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Customer } from '../customer.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { CompanySearchComponent } from '../company-search/company-search.component';
import { mimeType } from './mime-type.validator';
import { Location } from '@angular/common';

@Component({
    selector: 'app-customer-create',
    templateUrl: './customer-create.component.html',
    styleUrls: ['./customer-create.component.scss'],
})
export class CustomerCreateComponent implements OnInit, OnDestroy {
    constructor(
        public cmsService: CmsService,
        public route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        public dialog: MatDialog,
        private location: Location
    ) {}

    mode = 'create';

    // 登録状況のバッジ表示制御
    editFlg;
    private postId: string;
    post: Customer;
    tb_company_cd;
    isLoading = false;
    unregistered = false;
    registerdState;
    file_name: string;
    imagePath: string;

    form: FormGroup;
    private authStatusSub: Subscription;

    ngOnInit() {
        this.authStatusSub = this.authService.getAuthStatusListener().subscribe((authStatus) => {
            this.isLoading = false;
        });
        this.form = new FormGroup({
            cd: new FormControl('未採番', {
                validators: [Validators.required],
            }),
            name: new FormControl(null, {
                validators: [Validators.required, Validators.minLength(2)],
            }),
            name_kana: new FormControl(null, {
                validators: [Validators.required, Validators.minLength(2)],
            }),
            companyName: new FormControl(null, {
                validators: [Validators.required],
            }),
            department: new FormControl(null, []),
            position: new FormControl(null, []),
            email: new FormControl(null, []),
            mobile_phone: new FormControl(null, []),
            registered_at: new FormControl(null, []),
            note: new FormControl(null, []),
            image: new FormControl(null, {
                asyncValidators: [mimeType],
            }),
            tb_company_cd: new FormControl(null, []),
        });
        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            if (paramMap.has('postId')) {
                this.mode = 'edit';
                this.postId = paramMap.get('postId');
                this.isLoading = true;
                this.cmsService.getCustomerPost(this.postId).subscribe((postData) => {
                    this.isLoading = false;
                    this.tb_company_cd = postData.tb_company_cd;
                    this.file_name = postData.file_name;
                    this.imagePath = postData.image_path;

                    if (postData.deleted_at) {
                        this.unregistered = true;
                    }

                    this.cmsService.getSelectedCompany(this.tb_company_cd).subscribe((companyData) => {
                        this.form.patchValue({
                            companyName: companyData.name,
                        });
                    });

                    this.post = {
                        id: postData.id,
                        cd: postData.cd,
                        tb_company_cd: postData.tb_company_cd,
                        name: postData.name,
                        name_kana: postData.name_kana,
                        department: postData.department,
                        position: postData.position,
                        email: postData.email,
                        mobile_phone: postData.mobile_phone,
                        image_path: postData.image_path,
                        registered_at: postData.registered_at,
                        note: postData.note,
                        deleted_at: postData.deleted_at,
                    };

                    // FormControlの初期値をnullにしているが、editの場合は値がある。そのためsetValueメソッドを追加し、その値に上書きする。
                    this.form.patchValue({
                        cd: this.post.cd,
                        tb_company_cd: this.post.tb_company_cd,
                        name: this.post.name,
                        name_kana: this.post.name_kana,
                        department: this.post.department,
                        position: this.post.position,
                        email: this.post.email,
                        mobile_phone: this.post.mobile_phone,
                        image: this.post.image_path,
                        registered_at: this.post.registered_at,
                        note: this.post.note,
                        deleted_at: this.post.deleted_at,
                    });

                    this.editFlg = true;

                    if (this.unregistered) {
                        this.registerdState = '削除済';
                    } else {
                        this.registerdState = '登録済';
                    }
                });
            } else {
                this.mode = 'create';
                this.postId = null;
                this.editFlg = false;
            }
        });
    }

    // 会社選択画面ポップアップ
    openDialog() {
        // preventDefaultを入れないと、ポップアップが出た瞬間にsubmitされてしまう
        event.preventDefault();
        let dialogRef = this.dialog.open(CompanySearchComponent, { maxHeight: '90vh' });
        dialogRef.afterClosed().subscribe((selectedId) => {
            if (selectedId) {
                this.cmsService.getSelectedCompany(selectedId).subscribe((companyData) => {
                    this.form.patchValue({
                        companyName: companyData.name,
                        tb_company_cd: companyData.cd,
                    });
                });
            }
            return;
        });
    }

    onRestore() {
        this.cmsService.restoreCompanyPost(this.tb_company_cd);
        this.cmsService.restoreCustomerPost(this.post.id);
    }

    onSaveCustomerPost() {
        if (this.form.invalid) {
            return;
        }
        this.isLoading = true;

        if (this.mode === 'create') {
            this.cmsService.addCustomerPost(
                this.form.value.name,
                this.form.value.name_kana,
                this.form.value.tb_company_cd,
                this.form.value.department,
                this.form.value.position,
                this.form.value.email,
                this.form.value.mobile_phone,
                this.form.value.image,
                this.form.value.registered_at,
                // this.form.value.creator,
                this.form.value.note
            );
        } else {
            this.cmsService.updateCustomerPost(
                this.postId,
                this.form.value.cd,
                this.form.value.name,
                this.form.value.name_kana,
                this.form.value.tb_company_cd,
                this.form.value.department,
                this.form.value.position,
                this.form.value.email,
                this.form.value.mobile_phone,
                this.form.value.image,
                this.file_name,
                this.form.value.registered_at,
                this.form.value.note
            );
        }
        this.form.reset();
    }

    onFilePicked(event: Event) {
        const file = (event.target as HTMLInputElement).files[0];
        this.form.patchValue({ image: file });
        this.form.get('image').updateValueAndValidity();
        const reader = new FileReader();
        reader.onload = () => {
            this.file_name = file.name;
        };
        reader.readAsDataURL(file);
    }

    goBack(): void {
        this.location.back();
    }

    ngOnDestroy() {
        this.authStatusSub.unsubscribe();
    }
}
