import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CategoryComponent } from './category/category.component';
import { NotFoundComponent } from './core/not-found/not-found.component';

import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
    { path: '', loadChildren: () => import('./cms/cms.module').then((m) => m.CmsModule) },
    { path: 'auth', loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule) },
    { path: 'item', loadChildren: () => import('./item/item.module').then((m) => m.ItemModule) },
    { path: 'order', loadChildren: () => import('./order/order.module').then((m) => m.OrderModule) },
    { path: 'related-item', loadChildren: () => import('./related-item/related-item.module').then((m) => m.RelatedItemModule) },
    { path: 'cms', loadChildren: () => import('./cms/cms.module').then((m) => m.CmsModule) },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'category', component: CategoryComponent },
    { path: '**', component: NotFoundComponent }

];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
    providers: [AuthGuard],
})
export class AppRoutingModule { }
