import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Order } from 'src/app/order/order.model';
import { environment } from 'src/environments/environment';

const ORDER_BACKEND_URL = environment.apiUrl + '/order/';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

    private orders: Order[] = [];
    private ordersUpdated = new Subject<Order[]>();

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    addOrderPost(
        order_number: number,
        customer_name: string,
        customer_cd: number,
        orderd_date: string,
        expected_shipping_day: string,
        returning_scheduled_date: string,
        all_returned_date: string,
        how_to_order: string,
        how_to_ship: string,
        user_id: number,
        note: string,
        print_note: string,
        message: string,
    ) {
        const post = {
            order_number,
            customer_name,
            customer_cd,
            orderd_date,
            expected_shipping_day,
            returning_scheduled_date,
            all_returned_date,
            how_to_order,
            how_to_ship,
            user_id,
            note,
            print_note,
            message,
        };

        this.http.post(ORDER_BACKEND_URL, post).subscribe(responseData => {
            this.orders.push(post);
            this.ordersUpdated.next([...this.orders]);
            this.router.navigate(['order-list']);
        })
    }

    getOrders() {
        this.http.get<{message: string; orders: Order[]}>(ORDER_BACKEND_URL).subscribe(orderData => {
            this.orders = orderData.orders;
            this.ordersUpdated.next([...this.orders]);
        });
    }

    getOrdersUpdateListener() {
        return this.ordersUpdated.asObservable();
    }
}
