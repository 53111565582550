<section class="body">
    <ng-container>
        <!-- スマホ用ヘッダー -->
        <div class="heading">
            <h1 class="section-title">会社名一覧</h1>
            <a routerLink="/company-create" class="heading__add" mat-dialog-close *ngIf="!viewer"
                ><img src="assets/img/common/add_icon2.svg"
            /></a>
            <div class="heading__search-block">
                <mat-form-field>
                    <mat-label>絞り込み</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="例）アンビ" />
                </mat-form-field>
                <div class="heading__post_count">
                    {{ post_count }}/<span>{{ current_count }}件</span>
                </div>
            </div>
            <mat-checkbox
                labelPosition="before"
                class="heading__search-option"
                [(ngModel)]="checked"
                (change)="onAddFilter($event)"
                color="primary"
                >削除済も表示</mat-checkbox
            >
        </div>

        <!-- PC用ヘッダー -->
        <div class="heading-pc">
            <h1 class="section-title">会社名一覧</h1>
            <div class="heading-pc__search-block">
                <mat-form-field>
                    <mat-label>絞り込み</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="例）アンビ" />
                </mat-form-field>
                <mat-checkbox
                    labelPosition="before"
                    class="heading-pc__search-option"
                    [(ngModel)]="checked"
                    (change)="onAddFilter($event)"
                    color="primary"
                    >削除済も表示</mat-checkbox
                >
                <div class="heading-pc__post_count">
                    {{ post_count }}/<span>{{ current_count }}件</span>
                </div>
            </div>
            <a routerLink="/company-create" class="heading-pc__btn secondary-button" mat-dialog-close *ngIf="!viewer"
                >会社名登録</a
            >
        </div>

        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="cd">
                <th mat-header-cell *matHeaderCellDef>会社名CD</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.deleted_at === null; else deleted_block">{{ element.cd }}</div>
                    <ng-template #deleted_block>
                        <div class="deleted_bg">{{ element.cd }}</div>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>会社名</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-button mat-dialog-close mat-dialog-close="{{ element.cd }}">
                        {{ element.name }}
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="branch_name">
                <th mat-header-cell *matHeaderCellDef>支店／拠点名</th>
                <td mat-cell *matCellDef="let element">{{ element.branch_name }}</td>
            </ng-container>

            <ng-container matColumnDef="prefecture">
                <th mat-header-cell *matHeaderCellDef>都道府県</th>
                <td mat-cell *matCellDef="let element">{{ element.prefecture }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [ngClass]="{ deleted_bg: row.deleted_at !== null }"
            ></tr>
        </table>

        <p class="info-text mat-body-1" *ngIf="posts.length <= 0">
            登録件数はゼロです。
        </p>
    </ng-container>
</section>

<mat-dialog-actions>
    <!-- <button mat-button mat-dialog-close mat-dialog-close="true">YES</button> -->
    <button mat-button mat-dialog-close mat-dialog-close="false">キャンセル</button>
</mat-dialog-actions>
