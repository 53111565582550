import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
    constructor(private authService: AuthService) {}

    ngOnInit() {
        this.authService.autoAuthUser();
    }

    close() {
        this.sidenav.close();
    }
    
    onLogout() {
        this.authService.logout();
    }
}
