<section class="body">
    <!-- スマホ用ヘッダー -->
    <div class="heading">
        <h1 class="section-title">注文一覧</h1>
        <a routerLink="/order-create" class="heading__add"><img src="assets/img/common/add_icon2.svg" /></a>
        <div class="heading__search-block">
            <mat-form-field>
                <mat-label>絞り込み</mat-label>
                <input matInput placeholder="例）12345" />
                <!-- <input matInput (keyup)="applyFilter($event)" placeholder="例）12345" /> -->
            </mat-form-field>
            <div class="heading__item_count">
                123/<span>234件</span>
                <!-- {{ relatedItemCount }}/<span>{{ current_count }}件</span> -->
            </div>
        </div>
        <mat-checkbox labelPosition="before" class="heading__search-option" color="primary">削除済も表示</mat-checkbox>
        <!-- <mat-checkbox labelPosition="before" class="heading__search-option" [(ngModel)]="checked"
            (change)="onAddFilter($event)" color="primary">削除済も表示</mat-checkbox> -->
    </div>

    <!-- PC用ヘッダー -->
    <div class="heading-pc">
        <div class="heading-pc__search-block">
            <h1 class="section-title">注文一覧</h1>
            <div class="filter">
                <mat-form-field class="per20 filter__year">
                    <mat-label>年</mat-label>
                    <mat-select [(value)]="year" tabindex="1">
                        <mat-option *ngFor="let year of years" [value]="year">
                            {{ year }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="per20">
                    <mat-label>月</mat-label>
                    <mat-select tabindex="2" (selectionChange)="getDatesPulldown()" [(ngModel)]="month">
                        <mat-option *ngFor="let month of months" [value]="month">
                            {{ month }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="per20">
                    <mat-label>日</mat-label>
                    <mat-select [(value)]="date" tabindex="3">
                        <mat-option *ngFor="let date of dates" [value]="date">
                            {{ date }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="filter__customer">
                    <mat-label>顧客番号</mat-label>
                    <input matInput placeholder="例）12345" />
                    <!-- <input matInput (keyup)="applyFilter($event)" placeholder="例）12345" /> -->
                </mat-form-field>
                <mat-checkbox labelPosition="before" class="heading-pc__search-option" color="primary">削除済も表示</mat-checkbox>
                <!-- <mat-checkbox labelPosition="before" class="heading-pc__search-option" [(ngModel)]="checked"
                    (change)="onAddFilter($event)" color="primary">削除済も表示</mat-checkbox> -->
                <div class="heading-pc__item_count">
                    123/<span>234件</span>
                    <!-- {{ relatedItemCount }}/<span>{{ current_count }}件</span> -->
                </div>
            </div>
        </div>
        <a routerLink="/order-create" class="heading-pc__btn secondary-button">注文登録</a>
        <!-- <a routerLink="/order-create" class="heading-pc__btn secondary-button" *ngIf="!viewer">注文登録</a> -->
    </div>

</section>
<mat-spinner *ngIf="isLoading"></mat-spinner>
<section>
    <div class="mat-elevation-z8">
        <mat-table *ngIf="!isLoading" [dataSource]="dataSource">
            <ng-container matColumnDef="customer_cd">
                <mat-header-cell *matHeaderCellDef>登録者CD</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div *ngIf="element.deleted_at === null; else deleted_block">{{ element.customer_cd }}</div>
                    <ng-template #deleted_block>
                        <div class="deleted_bg">{{ element.customer_cd }}</div>
                    </ng-template>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="company_name">
                <mat-header-cell *matHeaderCellDef>会社名</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div *ngIf="element.deleted_at === null; else deleted_block">{{ element.TbCustomer.TbCompany.name }}</div>
                    <ng-template #deleted_block>
                        <div class="deleted_bg">{{ element.TbCustomer.TbCompany.name }}</div>
                    </ng-template>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="customer_name">
                <mat-header-cell *matHeaderCellDef>登録者名</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div *ngIf="element.deleted_at === null; else deleted_block">{{ element.TbCustomer.name }}</div>
                    <ng-template #deleted_block>
                        <div class="deleted_bg">{{ element.TbCustomer.name }}</div>
                    </ng-template>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="expected_shipping_day">
                <mat-header-cell *matHeaderCellDef>出荷予定日</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div *ngIf="element.deleted_at === null; else deleted_block">{{ element.expected_shipping_day | date: 'yyyy年MM月dd日' }}</div>
                    <ng-template #deleted_block>
                        <div class="deleted_bg">{{ element.expected_shipping_day | date: 'yyyy年MM月dd日' }}</div>
                    </ng-template>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="shipped_day">
                <mat-header-cell *matHeaderCellDef>出荷日</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div *ngIf="element.deleted_at === null; else deleted_block">{{ element.shipped_day | date: 'yyyy年MM月dd日' }}</div>
                    <ng-template #deleted_block>
                        <div class="deleted_bg">{{ element.shipped_day | date: 'yyyy年MM月dd日' }}</div>
                    </ng-template>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="all_returned_date">
                <mat-header-cell *matHeaderCellDef>返却日</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div *ngIf="element.deleted_at === null; else deleted_block">{{ element.all_returned_date | date: 'yyyy年MM月dd日' }}</div>
                    <ng-template #deleted_block>
                        <div class="deleted_bg">{{ element.all_returned_date | date: 'yyyy年MM月dd日' }}</div>
                    </ng-template>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="subtotal">
                <mat-header-cell *matHeaderCellDef>基本レンタル料金</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div *ngIf="element.deleted_at === null; else deleted_block">{{ element.subtotal }}</div>
                    <ng-template #deleted_block>
                        <div class="deleted_bg">{{ element.subtotal }}</div>
                    </ng-template>
                </mat-cell>
            </ng-container>
    
    
            <!-- アイコン -->
            <!-- <ng-container *ngIf="!viewer; else not_login">
                <ng-container matColumnDef="icon">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="icon">
                        <ul class="icon__list">
                            <li class="icon__item">
                                <a [routerLink]="['/item-edit', element.cd]" title="編集"><i class="icon__edit"></i></a>
                            </li>
    
                            <li *ngIf="element.deleted_at === null" class="icon__item">
                                <a (click)="onItemDelete(element.id)" title="削除"><i class="icon__delete"></i></a>
                            </li>
                            <li *ngIf="element.deleted_at !== null" class="icon__item">
                                <i class="icon__delete" style="cursor: default; pointer-events: none"></i>
                            </li>
                        </ul>
                    </mat-cell>
                </ng-container>
            </ng-container> -->
            <!-- <ng-template #not_login>
                <ng-container matColumnDef="icon">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="icon"></mat-cell>
                </ng-container>
            </ng-template> -->
    
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ deleted_bg: row.deleted_at !== null }">
            </mat-row>
        </mat-table>
    </div>
    
    <p class="info-text mat-body-1" *ngIf="orders.length <= 0">登録件数はゼロです。</p>
    <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
</section>