<section>
    <div class="body">
        <h1 class="section-title" *ngIf="mode == 'create'; else editH1">登録者追加</h1>
        <ng-template #editH1>
            <h1 class="section-title">登録者修正</h1>
        </ng-template>
        <mat-card>
            <mat-spinner *ngIf="isLoading"></mat-spinner>
            <form [formGroup]="form" (submit)="onSaveCustomerPost()" *ngIf="!isLoading">
                <input type="hidden" formControlName="tb_company_cd" />
                <div class="card__heading">
                    <div *ngIf="editFlg" class="registerd_state" [class.unregistered]="unregistered">
                        {{ registerdState }}
                    </div>
                </div>

                <div class="card">
                    <mat-form-field appearance="fill">
                        <mat-label>登録者CD</mat-label>
                        <input matInput type="text" formControlName="cd" tabindex="1" *ngIf="editFlg" />
                        <input matInput type="text" formControlName="cd" tabindex="1" *ngIf="!editFlg" readonly />
                        <mat-error *ngIf="form.get('cd').invalid">登録者CDは必須です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>登録者名</mat-label>
                        <input matInput type="text" formControlName="name" tabindex="2" />
                        <mat-error *ngIf="form.get('name').invalid">登録者名は2文字以上必要です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>登録者名（かな）</mat-label>
                        <input matInput type="text" formControlName="name_kana" tabindex="3" />
                        <mat-error *ngIf="form.get('name_kana').invalid">登録者名は2文字以上必要です</mat-error>
                    </mat-form-field>
                    <div class="company-name">
                        <mat-form-field appearance="fill">
                            <mat-label>会社名</mat-label>
                            <input matInput type="text" formControlName="companyName" tabindex="3" readonly />
                            <mat-error *ngIf="form.get('companyName').invalid">会社名は必須です</mat-error>
                        </mat-form-field>
                        <button class="company-button" mat-button tabindex="4">
                            <mat-icon [inline]="true" (click)="openDialog()">apartment</mat-icon>
                        </button>
                    </div>
                    <mat-form-field appearance="fill">
                        <mat-label>部署名</mat-label>
                        <input matInput type="text" formControlName="department" tabindex="5" />
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="position">
                        <mat-label>肩書</mat-label>
                        <input matInput type="text" formControlName="position" tabindex="6" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>メールアドレス</mat-label>
                        <input matInput type="text" formControlName="email" tabindex="7" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>携帯電話</mat-label>
                        <input matInput type="text" formControlName="mobile_phone" tabindex="8" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>登録日</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="registered_at" tabindex="9" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="fill" class="note">
                    <mat-label>備考</mat-label>
                    <textarea matInput formControlName="note" tabindex="10"></textarea>
                </mat-form-field>
                <div class="file-select">
                    <div>
                        <button mat-stroked-button type="button" (click)="filePicker.click()">ファイル選択</button>
                        <input type="file" #filePicker (change)="onFilePicked($event)" />
                    </div>
                    <div
                        class="file_name"
                        *ngIf="file_name !== '' && file_name && form.get('image').valid && mode == 'create'"
                    >
                        <p>{{ file_name }}</p>
                    </div>
                    <div
                        class="file_name"
                        *ngIf="file_name !== '' && file_name && form.get('image').valid && mode == 'edit'"
                    >
                        <a [href]="imagePath" class="file_name_link">{{ file_name }}</a>
                    </div>
                </div>
                <div class="submit-flex">
                    <div class="submit-flex__inner">
                        <button mat-raised-button class="submit-button" color="accent" type="submit">
                            <div *ngIf="mode == 'create'; else edit">
                                登録する
                            </div>
                            <ng-template #edit>
                                <div class="deleted_bg">更新する</div>
                            </ng-template>
                        </button>
                        <a mat-raised-button class="submit-button" color="basic" (click)="goBack()">戻る</a>
                    </div>
                    <p class="revive-button" *ngIf="unregistered" (click)="onRestore()">復活させる</p>
                </div>
            </form>
        </mat-card>
    </div>
</section>
