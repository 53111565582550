<section>
    <div class="body">
        <h1 class="section-title" *ngIf="mode == 'create'; else editH1">商品登録</h1>
        <ng-template #editH1>
            <h1 class="section-title">商品修正</h1>
        </ng-template>
        <form [formGroup]="form" (submit)="onSaveItemPost()" ybAddress *ngIf="!isLoading">
            <mat-spinner *ngIf="isLoading"></mat-spinner>
            <div class="card__heading">
                <div *ngIf="editFlg" class="registerd_state" [class.unregistered]="unregistered">
                    {{ registerdState }}
                </div>
            </div>
            <div class="mat-card-wrap">
                <!-- 左側 -->
                <mat-card>
                    <!-- <div class="card"> -->
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD</mat-label>
                        <input matInput type="text" formControlName="cd" tabindex="1" *ngIf="editFlg" />
                        <input matInput type="text" formControlName="cd" tabindex="1" *ngIf="!editFlg" readonly />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品名</mat-label>
                        <input matInput type="text" formControlName="name" tabindex="2" />
                        <mat-error *ngIf="form.get('name').invalid">商品名は2文字以上必要です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>在庫数</mat-label>
                        <input matInput type="number" formControlName="stock" tabindex="3" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>寸法</mat-label>
                        <input matInput type="text" formControlName="size" tabindex="4" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>レンタル料金（税抜）</mat-label>
                        <input matInput type="number" formControlName="rental_price" tabindex="5" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>棚番号</mat-label>
                        <input matInput type="text" formControlName="shelf_no" tabindex="6" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>公開フラグ</mat-label>
                        <mat-select formControlName="open_flg" tabindex="7">
                            <mat-option *ngFor="let result of is_open" [value]="result.value">
                                {{ result.viewValue }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>キーワード</mat-label>
                        <input matInput type="text" formControlName="keyword" tabindex="8" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>購入店名</mat-label>
                        <input matInput type="text" formControlName="purchased_shop" tabindex="9" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>定価（税込）</mat-label>
                        <input matInput type="number" formControlName="purchased_list_price" tabindex="10" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>購入金額（税込）</mat-label>
                        <input matInput type="number" formControlName="purchased_price" tabindex="11" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>購入個数</mat-label>
                        <input matInput type="number" formControlName="purchased_quantity" tabindex="12" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>購入日</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="date_of_purchase" tabindex="13" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="note">
                        <mat-label>備考（フロント用）</mat-label>
                        <textarea matInput formControlName="note_web" tabindex="14"></textarea>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="note">
                        <mat-label>備考（バック用）</mat-label>
                        <textarea matInput formControlName="note" tabindex="15"></textarea>
                    </mat-form-field>
                    <!-- </div> -->
                </mat-card>

                <!-- 右側 -->
                <mat-card>
                    <h2 class="category_title">カテゴリ1</h2>
                    <mat-form-field appearance="fill">
                        <mat-label>大項目</mat-label>
                        <mat-select formControlName="major1" #major1 (selectionChange)="majorChangeAction(major1, 1)">
                            <mat-option *ngFor="let result of major_name" [value]="result.value">{{
                                result.viewValue
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>小項目</mat-label>
                        <mat-select formControlName="category_minor_cd1">
                            <mat-option>-- None --</mat-option>
                            <mat-optgroup *ngFor="let middle of middleCatList1" [label]="middle.name">
                                <mat-option
                                    *ngFor="let minor of middle.MtCategoryMinors"
                                    [value]="minor.category_minor_cd"
                                >
                                    {{ minor.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>

                    <h2 class="category_title">カテゴリ2</h2>
                    <mat-form-field appearance="fill">
                        <mat-label>大項目</mat-label>
                        <mat-select formControlName="major2" #major2 (selectionChange)="majorChangeAction(major2, 2)">
                            <mat-option *ngFor="let result of major_name" [value]="result.value">{{
                                result.viewValue
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>小項目</mat-label>
                        <mat-select formControlName="category_minor_cd2">
                            <mat-option>-- None --</mat-option>
                            <mat-optgroup *ngFor="let middle of middleCatList2" [label]="middle.name">
                                <mat-option
                                    *ngFor="let minor of middle.MtCategoryMinors"
                                    [value]="minor.category_minor_cd"
                                >
                                    {{ minor.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>

                    <h2 class="category_title">カテゴリ3</h2>
                    <mat-form-field appearance="fill">
                        <mat-label>大項目</mat-label>
                        <mat-select formControlName="major3" #major3 (selectionChange)="majorChangeAction(major3, 3)">
                            <mat-option *ngFor="let result of major_name" [value]="result.value">{{
                                result.viewValue
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>小項目</mat-label>
                        <mat-select formControlName="category_minor_cd3">
                            <mat-option>-- None --</mat-option>
                            <mat-optgroup *ngFor="let middle of middleCatList3" [label]="middle.name">
                                <mat-option
                                    *ngFor="let minor of middle.MtCategoryMinors"
                                    [value]="minor.category_minor_cd"
                                >
                                    {{ minor.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>

                    <h2 class="category_title">カテゴリ4</h2>
                    <mat-form-field appearance="fill">
                        <mat-label>大項目</mat-label>
                        <mat-select formControlName="major4" #major4 (selectionChange)="majorChangeAction(major4, 4)">
                            <mat-option *ngFor="let result of major_name" [value]="result.value">{{
                                result.viewValue
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>小項目</mat-label>
                        <mat-select formControlName="category_minor_cd4">
                            <mat-option>-- None --</mat-option>
                            <mat-optgroup *ngFor="let middle of middleCatList4" [label]="middle.name">
                                <mat-option
                                    *ngFor="let minor of middle.MtCategoryMinors"
                                    [value]="minor.category_minor_cd"
                                >
                                    {{ minor.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </mat-card>
            </div>
            <div class="submit-flex">
                <div class="submit-flex__inner">
                    <button mat-raised-button class="submit-button" color="accent" type="submit">
                        <div *ngIf="mode == 'create'; else edit">登録する</div>
                        <ng-template #edit>
                            <div class="deleted_bg">更新する</div>
                        </ng-template>
                    </button>
                    <a mat-raised-button class="submit-button" color="basic" (click)="goBack()">戻る</a>
                </div>
                <p class="revive-button" *ngIf="unregistered" (click)="onRestore()">復活させる</p>
            </div>
        </form>
    </div>
</section>
