import { AuthService } from '../../core/services/auth.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Company } from '../company.model';
import { CmsService } from '../../core/services/cms.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
    selector: 'app-company-list',
    templateUrl: './company-list.component.html',
    styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit, OnDestroy {
    posts: Company[] = [];
    target_customers_cd = [];
    post_count;
    current_count;
    private postsSub: Subscription;
    isLoading = false;
    userRole;
    viewer = false;
    public firstTab = 0;
    displayedColumns: string[];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    dataSource;
    dataSource_a;
    dataSource_ka;
    dataSource_sa;
    dataSource_ta;
    dataSource_na;
    dataSource_ha;
    dataSource_ma;
    dataSource_ya;
    checked = false;

    constructor(public cmsService: CmsService, private authService: AuthService, private router: Router) {}

    ngOnInit() {
        this.isLoading = true;
        this.cmsService.getCompanyPosts();

        this.userRole = this.authService.getRoleData();
        if (this.userRole === 'viewer') {
            this.viewer = true;
        }

        this.postsSub = this.cmsService.getCompanyPostsUpdateListener().subscribe((posts: Company[]) => {
            this.posts = posts;
            this.post_count = Object.keys(posts).length;
            this.current_count = Object.keys(posts).length;

            // 全件
            this.dataSource = new MatTableDataSource(posts);
            this.dataSource.paginator = this.paginator;

            // あ行
            const posts_a = posts.filter((item) => {
                switch (item.name_kana.slice(0, 1)) {
                    case 'あ':
                    case 'い':
                    case 'う':
                    case 'え':
                    case 'お':
                        return true;
                        break;
                }
            });
            this.dataSource_a = new MatTableDataSource(posts_a);
            let sort_a = this.dataSource_a.data.sort((a, b) => {
                if (a.name_kana > b.name_kana) {
                    return 1;
                } else {
                    return -1;
                }
            });
            this.dataSource_a = new MatTableDataSource(sort_a);

            // か行
            const posts_ka = posts.filter((item) => {
                switch (item.name_kana.slice(0, 1)) {
                    case 'か':
                    case 'き':
                    case 'く':
                    case 'け':
                    case 'こ':
                        return true;
                        break;
                }
            });
            this.dataSource_ka = new MatTableDataSource(posts_ka);
            let sort_ka = this.dataSource_ka.data.sort((a, b) => {
                if (a.name_kana > b.name_kana) {
                    return 1;
                } else {
                    return -1;
                }
            });
            this.dataSource_ka = new MatTableDataSource(sort_ka);

            // さ行
            const posts_sa = posts.filter((item) => {
                switch (item.name_kana.slice(0, 1)) {
                    case 'さ':
                    case 'し':
                    case 'す':
                    case 'せ':
                    case 'そ':
                        return true;
                        break;
                }
            });
            this.dataSource_sa = new MatTableDataSource(posts_sa);
            let sort_sa = this.dataSource_sa.data.sort((a, b) => {
                if (a.name_kana > b.name_kana) {
                    return 1;
                } else {
                    return -1;
                }
            });
            this.dataSource_sa = new MatTableDataSource(sort_sa);

            // た行
            const posts_ta = posts.filter((item) => {
                switch (item.name_kana.slice(0, 1)) {
                    case 'た':
                    case 'ち':
                    case 'つ':
                    case 'て':
                    case 'と':
                        return true;
                        break;
                }
            });
            this.dataSource_ta = new MatTableDataSource(posts_ta);
            let sort_ta = this.dataSource_ta.data.sort((a, b) => {
                if (a.name_kana > b.name_kana) {
                    return 1;
                } else {
                    return -1;
                }
            });
            this.dataSource_ta = new MatTableDataSource(sort_ta);

            // な行
            const posts_na = posts.filter((item) => {
                switch (item.name_kana.slice(0, 1)) {
                    case 'な':
                    case 'に':
                    case 'ぬ':
                    case 'ね':
                    case 'の':
                        return true;
                        break;
                }
            });
            this.dataSource_na = new MatTableDataSource(posts_na);
            let sort_na = this.dataSource_na.data.sort((a, b) => {
                if (a.name_kana > b.name_kana) {
                    return 1;
                } else {
                    return -1;
                }
            });
            this.dataSource_na = new MatTableDataSource(sort_na);

            // は行
            const posts_ha = posts.filter((item) => {
                switch (item.name_kana.slice(0, 1)) {
                    case 'は':
                    case 'ひ':
                    case 'ふ':
                    case 'へ':
                    case 'ほ':
                        return true;
                        break;
                }
            });
            this.dataSource_ha = new MatTableDataSource(posts_ha);
            let sort_ha = this.dataSource_ha.data.sort((a, b) => {
                if (a.name_kana > b.name_kana) {
                    return 1;
                } else {
                    return -1;
                }
            });
            this.dataSource_ha = new MatTableDataSource(sort_ha);

            // ま行
            const posts_ma = posts.filter((item) => {
                switch (item.name_kana.slice(0, 1)) {
                    case 'ま':
                    case 'み':
                    case 'む':
                    case 'め':
                    case 'も':
                        return true;
                        break;
                }
            });
            this.dataSource_ma = new MatTableDataSource(posts_ma);
            let sort_ma = this.dataSource_ma.data.sort((a, b) => {
                if (a.name_kana > b.name_kana) {
                    return 1;
                } else {
                    return -1;
                }
            });
            this.dataSource_ma = new MatTableDataSource(sort_ma);

            // や・ら・わ行
            const posts_ya = posts.filter((item) => {
                switch (item.name_kana.slice(0, 1)) {
                    case 'や':
                    case 'ゆ':
                    case 'よ':
                    case 'ら':
                    case 'り':
                    case 'る':
                    case 'れ':
                    case 'ろ':
                    case 'わ':
                    case 'を':
                    case 'ん':
                        return true;
                        break;
                }
            });
            this.dataSource_ya = new MatTableDataSource(posts_ya);
            let sort_ya = this.dataSource_ya.data.sort((a, b) => {
                if (a.name_kana > b.name_kana) {
                    return 1;
                } else {
                    return -1;
                }
            });
            this.dataSource_ya = new MatTableDataSource(sort_ya);

            this.isLoading = false;

            // 画像解像度と権限によって表示カラムを変更
            // 解像度が1024px以上で権限がadmin, editorの場合
            if (window.matchMedia('(min-width: 1024px)').matches && this.userRole !== 'viewer') {
                this.displayedColumns = [
                    'cd',
                    'name',
                    'branch_name',
                    'prefecture',
                    // 'tel',
                    // 'fax',
                    'closing_day',
                    'bill',
                    'icon',
                ];

                // 解像度が1024px以上で権限がviewerの場合
            } else if (window.matchMedia('(min-width: 1024px)').matches && this.userRole === 'viewer') {
                this.displayedColumns = [
                    'cd',
                    'name',
                    'branch_name',
                    'prefecture',
                    // 'tel',
                    // 'fax',
                    'closing_day',
                    'bill',
                ];

                // 解像度が1024px以下で権限がadmin, editorの場合
            } else if (window.matchMedia('(max-width: 1024px)').matches && this.userRole !== 'viewer') {
                this.displayedColumns = ['cd', 'name', 'branch_name', 'closing_day', 'icon'];

                // 上記以外の場合
            } else {
                this.displayedColumns = ['cd', 'name', 'branch_name', 'closing_day'];
            }
        });
    }

    onCompanyDelete(postCd: string) {
        this.isLoading = true;
        this.cmsService.deleteCompanyPost(postCd);
        this.cmsService.getCompanyCustomerPostCd(postCd).subscribe((results) => {
            let target_customer_posts = results;

            for (let i = 0; i < target_customer_posts.cd.length; i++) {
                this.target_customers_cd.push(target_customer_posts.cd[i]);
            }
            this.cmsService.deleteCustomerPosts(this.target_customers_cd);
        });
        // this.router.navigate(['company-list']);
    }

    // 絞り込み
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        // 絞り込み結果件数
        this.current_count = Object.keys(this.dataSource.filteredData).length;
    }

    // 削除済も表示押下時
    onAddFilter(e) {
        this.isLoading = true;
        if (!e.source.checked) {
            this.cmsService.getCompanyPosts();
            this.postsSub = this.cmsService.getCompanyPostsUpdateListener().subscribe((posts: Company[]) => {
                this.isLoading = false;
                this.posts = posts;
                this.post_count = Object.keys(posts).length;
                this.current_count = Object.keys(posts).length;

                // 全件
                this.dataSource = new MatTableDataSource(posts);
            });
        } else {
            this.cmsService.getCompanyPostsWithDeleted();
            this.postsSub = this.cmsService.getCompanyPostsUpdateListener().subscribe((posts: Company[]) => {
                this.isLoading = false;
                this.posts = posts;
                this.post_count = Object.keys(posts).length;
                this.current_count = Object.keys(posts).length;

                // 全件
                this.dataSource = new MatTableDataSource(posts);
            });
        }
    }

    // 新着順以外のリストで絞り込みをした際、強制的に新着順タブに移動する（そうしないと絞り込めない）
    public moveTab() {
        this.firstTab = 0;
    }

    ngOnDestroy() {
        this.postsSub.unsubscribe();
    }
}
