import { AuthService } from '../../core/services/auth.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Item } from '../item.model';
import { ItemService } from '../../core/services/item.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from '../../../environments/environment';
import { CategoryService } from 'src/app/core/services/category.service';

const ITEMPIC_BACKEND_URL = environment.picUrl;

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit, OnDestroy {
    items: Item[] = [];
    minorCatName: string;
    middleCatName: string;
    middleCatMajorId: number;
    majorCatName: string;
    breadCrumb = '全て';

    private itemsSub: Subscription;
    private breadCrumbSub: Subscription;

    item_count;
    current_count;
    isLoading = false;
    userRole;
    viewer = false;
    displayedColumns: string[];
    categoryCd: number;

    dataSource;
    checked = false;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        public itemService: ItemService, 
        public categoryService: CategoryService,
        private authService: AuthService) { }

    ngOnInit() {
        this.onGetItems(this.categoryCd);
    }

    // 商品検索
    onGetItems(categoryCd) {
        this.isLoading = true;
        this.itemService.getItems(categoryCd);

        this.userRole = this.authService.getRoleData();
        if (this.userRole === 'viewer') {
            this.viewer = true;
        }

        this.itemsSub = this.itemService.getItemsUpdateListener().subscribe((items: Item[]) => {
            this.items = items;

            this.item_count = Object.keys(items).length;
            this.current_count = Object.keys(items).length;

            this.dataSource = new MatTableDataSource(items);
            this.dataSource.paginator = this.paginator;

            this.isLoading = false;


            // 画像解像度と権限によって表示カラムを変更
            // 解像度が1024px以上で権限がadmin, editorの場合
            if (window.matchMedia('(min-width: 1024px)').matches && this.userRole !== 'viewer') {
                this.displayedColumns = [
                    'cd',
                    'pic', 
                    'name',
                    'rental_price',
                    'stock',
                    'count',
                    'open_flg',
                    'icon',
                ];

            // 解像度が1024px以上で権限がviewerの場合
            } else if (window.matchMedia('(min-width: 1024px)').matches && this.userRole === 'viewer') {
                this.displayedColumns = [
                    'cd',
                    'pic', 
                    'name',
                    'rental_price',
                    'stock',
                    'count',
                    'open_flg',
                ];

            // 解像度が1024px以下で権限がadmin, editorの場合
            } else if (window.matchMedia('(max-width: 1024px)').matches && this.userRole !== 'viewer') {
                this.displayedColumns = [
                    'cd',
                    'name',
                    'rental_price',
                    'icon',
                ];

            // 上記以外の場合
            } else {
                this.displayedColumns = [
                    'cd',
                    'name',
                    'rental_price',
                ];
            }    
        });
    }
    
    onItemDelete(postId: number) {
        this.isLoading = true;
        this.itemService.deleteItem(postId);
    }

    // 絞り込み
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        // 絞り込み結果件数
        this.current_count = Object.keys(this.dataSource.filteredData).length;
    }

    // 削除済も表示押下時
    onAddFilter(e) {
        this.isLoading = true;
        if (!e.source.checked) {
            this.onGetItems(this.categoryCd);
        } else {
            this.itemService.getItemsWithDeleted(this.categoryCd);
            this.itemsSub = this.itemService.getItemsUpdateListener().subscribe((items: Item[]) => {
                this.isLoading = false;
                this.items = items;
                this.item_count = Object.keys(items).length;
                this.current_count = Object.keys(items).length;

                // 全件
                this.dataSource = new MatTableDataSource(items);
                this.dataSource.paginator = this.paginator;
                this.isLoading = false;
            });
        }
    }
    
    getItemPic(element) {
        const cd = element.cd;
        // 5桁の文字列に変更
        const cd5 = ('00000' + cd).slice(-5);
        const path = ITEMPIC_BACKEND_URL + '/' + cd5 + '/' + cd5 + '-1-250.jpg';
        return path;
    }

    // カテゴリー指定検索
    getCategory(cd:number) {
        // 選択したカテゴリーCDを取得
        this.categoryCd = cd;

        // 選択したminor_cdの末尾2桁を取得
        const cdLast2Degit = ('00000' + cd).slice(-2);

        // minor_cdが「全て見る = 00」の場合
        if (cdLast2Degit == '00') {
            // 先頭の3文字(middle_cd)を抽出
            const first_3_degit = String(cd).slice(0,3);
            this.categoryCd = Number(first_3_degit);
        }

        // 選択したカテゴリーの商品検索
        this.onGetItems(this.categoryCd);

        // パンくず用データ取得
        this.onGetCatBreadcrumb(this.categoryCd);
    }

    onGetCatBreadcrumb(minorCd:number) {
        if (minorCd === 0) {
            this.breadCrumb = '全て';
            return;
        }
        this.categoryService.getCatBreadcrumb(minorCd);
        this.breadCrumbSub = this.categoryService.getBreadCrumbUpdateListener().subscribe(breadCrumb => {
            this.minorCatName = breadCrumb['minor']['name'];
            this.middleCatName = breadCrumb['middle']['name'];
            this.majorCatName = breadCrumb['major']['name'];
    
            // 表示用パンくずリスト生成
            this.breadCrumb = `${this.majorCatName}　>　${this.middleCatName}　>　${this.minorCatName}`;
        })
    }

    ngOnDestroy() {
        this.itemsSub.unsubscribe();

        // カテゴリで絞り込みをした場合のみunsubscribeさせる
        if (this.breadCrumbSub) {
            this.breadCrumbSub.unsubscribe();
        }
    }

}
