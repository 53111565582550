<header>
    <div class="header">
        <div class="header-left">
            <a routerLink="" class="logo-link">
                <img src="assets/img/common/logo.svg" alt="anvi-systemロゴ" class="logo" />
            </a>
            <ul class="header-left__list">
                <li class="header-left__item">
                    <a
                        routerLink="cms/customer-list"
                        class="header-left__link"
                        routerLinkActive="active"
                        [class.pink]="isCustomerList()"
                        >登録者</a
                    >
                </li>
                <li class="header-left__item">
                    <a
                        routerLink="cms/company-list"
                        class="header-left__link"
                        routerLinkActive="active"
                        [class.pink]="isCompanyList()"
                        >会社名</a
                    >
                </li>
                <!-- <li class="header-left__item">
                    <a routerLink="/item/item-list" class="header-left__link" routerLinkActive="active">商品</a>
                </li>
                <li class="header-left__item">
                    <a routerLink="/related-item/related-item-list" class="header-left__link" routerLinkActive="active"
                        >関連商品</a
                    >
                </li>
                <li class="header-left__item" routerLinkActive="active">
                    <a
                        routerLink="/order/order-list"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="header-left__link"
                        >注文</a
                    >
                </li> -->
            </ul>
        </div>

        <ul class="header-right">
            <li *ngIf="userIsAuthenticated" class="header-right__item">
                <a href="">
                    <img
                        src="./assets/img/common/notifications_icon.svg"
                        alt="お知らせ"
                        class="header-right__notification-pic"
                    />
                </a>
            </li>
            <li *ngIf="!userIsAuthenticated" class="header-right__item">
                <button mat-button routerLink="/auth/login" class="header-right__email">ログイン</button>
            </li>
            <li *ngIf="userRole == 'admin'" class="header-right__item">
                <button mat-button routerLink="/auth/signup" class="header-right__email">ユーザー登録</button>
            </li>
            <li *ngIf="userIsAuthenticated" class="header-right__item">
                <button mat-button [matMenuTriggerFor]="userEmail">{{ authService.userEmail }}</button>
                <mat-menu #userEmail="matMenu">
                    <button mat-menu-item (click)="onLogout()">ログアウト</button>
                </mat-menu>
            </li>
        </ul>
    </div>
</header>
