<section>
    <div class="body">
        <h1 class="section-title">登録者閲覧</h1>
        <mat-card>
            <mat-spinner *ngIf="isLoading"></mat-spinner>

            <div class="card__heading">
                <div class="registerd_state" [class.unregistered]="unregistered">
                    {{ registerdState }}
                </div>
            </div>
            <div class="box">
                <h2 class="box__title">登録者情報</h2>
                <table class="box__table">
                    <tr>
                        <th>登録者CD</th>
                        <td>{{ post?.cd }}</td>
                    </tr>
                    <tr>
                        <th>法人・個人</th>
                        <td>{{ tb_company_is_company }}</td>
                    </tr>
                    <tr>
                        <th>登録者名</th>
                        <td>{{ post?.name }}</td>
                    </tr>
                    <tr>
                        <th>登録者名かな</th>
                        <td>{{ post?.name_kana }}</td>
                    </tr>
                    <tr>
                        <th>部署名</th>
                        <td>{{ post?.department }}</td>
                    </tr>
                    <tr>
                        <th>肩書</th>
                        <td>{{ post?.position }}</td>
                    </tr>
                    <tr>
                        <th>携帯電話</th>
                        <td>{{ post?.mobile_phone }}</td>
                    </tr>
                    <tr>
                        <th>メールアドレス</th>
                        <td>{{ post?.email }}</td>
                    </tr>
                    <tr>
                        <th>備考</th>
                        <td>{{ post?.note }}</td>
                    </tr>
                    <tr>
                        <th>登録日</th>
                        <td>{{ post?.registered_at | date : 'yyyy年MM月dd日' }}</td>
                    </tr>
                    <tr>
                        <th>更新日</th>
                        <td>{{ post?.updated_at | date : 'yyyy年MM月dd日' }}</td>
                    </tr>
                    <tr>
                        <th>削除日</th>
                        <td>{{ post?.deleted_at | date : 'yyyy年MM月dd日' }}</td>
                    </tr>
                    <tr>
                        <th>ファイル</th>
                        <td>
                            <a href="{{ post?.image_path }}">{{ post?.file_name }}</a>
                        </td>
                    </tr>
                </table>

                <h2 class="box__title">会社情報</h2>
                <table>
                    <tr>
                        <th>会社名CD</th>
                        <td>{{ tb_company_cd }}</td>
                    </tr>
                    <tr>
                        <th>会社名</th>
                        <td>{{ tb_company_name }}</td>
                    </tr>
                    <tr>
                        <th>支店／拠点名</th>
                        <td>{{ tb_company_branch_name }}</td>
                    </tr>
                    <tr>
                        <th>郵便番号</th>
                        <td>{{ tb_company_zip }}</td>
                    </tr>
                    <tr>
                        <th>住所</th>
                        <td>
                            {{ tb_company_prefecture }}{{ tb_company_address }} {{ tb_company_building }}
                            {{ tb_company_room_no }}
                        </td>
                    </tr>
                    <tr>
                        <th>電話番号</th>
                        <td>{{ tb_company_tel }}</td>
                    </tr>
                    <tr>
                        <th>FAX</th>
                        <td>{{ tb_company_fax }}</td>
                    </tr>
                    <tr>
                        <th>締日</th>
                        <td>{{ tb_company_closing_day }}</td>
                    </tr>
                    <tr>
                        <th>請求書送付方法</th>
                        <td>{{ tb_company_bill }}</td>
                    </tr>
                    <tr>
                        <th>登録日</th>
                        <td>{{ tb_company_registered_at | date : 'yyyy年MM月dd日' }}</td>
                    </tr>
                    <tr>
                        <th>更新日</th>
                        <td>{{ tb_company_updated_at | date : 'yyyy年MM月dd日' }}</td>
                    </tr>
                    <tr>
                        <th>備考</th>
                        <td>{{ tb_company_note }}</td>
                    </tr>
                    <tr>
                        <th>削除日</th>
                        <td>{{ tb_company_deleted_at }}</td>
                    </tr>
                </table>
            </div>

            <div class="submit-flex">
                <button mat-raised-button class="submit-button" color="basic" (click)="goBack($event)">戻る</button>
            </div>
        </mat-card>
    </div>
</section>
