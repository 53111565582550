import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material.module';
import { RouterModule } from '@angular/router';
import { RelatedItemRoutingModule } from './related_item_routing.module';
import { RelatedItemListComponent } from './related-item-list/related-item-list.component';
import { RelatedItemCreateComponent } from './related-item-create/related-item-create.component';



@NgModule({
    declarations: [
        RelatedItemListComponent,
        RelatedItemCreateComponent
    ],
    imports: [
        CommonModule,
        RelatedItemRoutingModule, 
        AngularMaterialModule, 
        RouterModule, 
        ReactiveFormsModule, 
        FormsModule, 
    ]
})
export class RelatedItemModule { }
