import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ItemService } from '../../core/services/item.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CategoryService } from 'src/app/core/services/category.service';
import { Category } from 'src/app/category/category.model';
import { Item } from 'src/app/item/item.model';


@Component({
    selector: 'app-item-create',
    templateUrl: './item-create.component.html',
    styleUrls: ['./item-create.component.scss']
})
export class ItemCreateComponent implements OnInit, OnDestroy {

    constructor(
        private location: Location,
        private authService: AuthService,
        private itemService: ItemService,
        private route: ActivatedRoute,
        private router: Router,
        public categoryService: CategoryService
    ) { }

    mode = 'create';
    editFlg;
    postCd: string;
    post: Item;
    unregistered = false;
    isLoading = false;
    form: FormGroup;
    registerdState;
    categories: Category[] = [];
    private categoriesSub: Subscription;
    private authStatusSub: Subscription;
    private minorSub: Subscription;
    middleCatList1: [];
    middleCatList2: [];
    middleCatList3: [];
    middleCatList4: [];
    minorName1: string;
    minorName2: string;
    minorName3: string;
    minorName4: string;


    is_open = [
        { value: '公開', viewValue: '公開' },
        { value: '非公開', viewValue: '非公開' },
    ];

    major_name = [
        { value: 1, viewValue: '洋食器' },
        { value: 2, viewValue: '和食器' },
        { value: 3, viewValue: 'テーブル小物' },
        { value: 4, viewValue: 'インテリア' },
        { value: 5, viewValue: '季節小物' },
    ];


    ngOnInit(): void {
        this.authStatusSub = this.authService.getAuthStatusListener().subscribe((authStatus) => {
            this.isLoading = false;
        });

        this.categoryService.getCategories();
        this.categoriesSub = this.categoryService.getCategoriesUpdateListener().subscribe((categories: Category[]) => {
            this.categories = categories;
        });

        this.form = new FormGroup({
            cd: new FormControl(null, []),
            name: new FormControl(null, {
                validators: [Validators.required, Validators.minLength(2)],
            }),
            stock: new FormControl(null, []),
            size: new FormControl(null, []),
            rental_price: new FormControl(null, []),
            shelf_no: new FormControl(null, []),
            open_flg: new FormControl('公開', []),
            keyword: new FormControl(null, []),
            purchased_shop: new FormControl(null, []),
            purchased_list_price: new FormControl(null, []),
            purchased_price: new FormControl(null, []),
            purchased_quantity: new FormControl(null, []),
            date_of_purchase: new FormControl(null, []),
            note_web: new FormControl(null, []),
            note: new FormControl(null, []),
            major1: new FormControl(null, []),
            major2: new FormControl(null, []),
            major3: new FormControl(null, []),
            major4: new FormControl(null, []),
            category_minor_cd1: new FormControl(null, []),
            category_minor_cd2: new FormControl(null, []),
            category_minor_cd3: new FormControl(null, []),
            category_minor_cd4: new FormControl(null, []),
            deleted_at: new FormControl(null, []),
        });
        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            if (paramMap.has('postCd')) {
                this.mode = 'edit';
                this.postCd = paramMap.get('postCd');
                this.isLoading = true;
                this.itemService.getItemPost(this.postCd).subscribe(postData => {
                    this.isLoading = false;

                    if (postData.deleted_at) {
                        this.unregistered = true;
                    }

                    this.post = {
                        cd: postData.cd,
                        name: postData.name,
                        stock: postData.stock,
                        size: postData.size,
                        rental_price: postData.rental_price,
                        shelf_no: postData.shelf_no,
                        open_flg: postData.open_flg,
                        keyword: postData.keyword,
                        purchased_shop: postData.purchased_shop,
                        purchased_list_price: postData.purchased_list_price,
                        purchased_price: postData.purchased_price,
                        purchased_quantity: postData.purchased_quantity,
                        date_of_purchase: postData.date_of_purchase,
                        note_web: postData.note_web,
                        note: postData.note,
                        category_minor_cd1: postData.category_minor_cd1,
                        category_minor_cd2: postData.category_minor_cd2,
                        category_minor_cd3: postData.category_minor_cd3,
                        category_minor_cd4: postData.category_minor_cd4,
                        deleted_at: postData.deleted_at,
                    };

                    // major_cdを抽出（minor_cdの先頭文字のみを抽出）
                    const major1String = '' + this.post.category_minor_cd1;
                    const major1 = major1String.slice(0, 1);
                    const major2String = '' + this.post.category_minor_cd2;
                    const major2 = major2String.slice(0, 1);
                    const major3String = '' + this.post.category_minor_cd3;
                    const major3 = major3String.slice(0, 1);
                    const major4String = '' + this.post.category_minor_cd4;
                    const major4 = major4String.slice(0, 1);

                    this.majorChangeAction(major1, 1);
                    this.majorChangeAction(major2, 2);
                    this.majorChangeAction(major3, 3);
                    this.majorChangeAction(major4, 4);


                    // FormControlの初期値をnullにしているが、editの場合は値がある。そのためsetValueメソッドを追加し、その値に上書きする。
                    this.form.setValue({
                        cd: this.post.cd,
                        name: this.post.name,
                        stock: this.post.stock,
                        size: this.post.size,
                        rental_price: this.post.rental_price,
                        shelf_no: this.post.shelf_no,
                        open_flg: this.post.open_flg,
                        keyword: this.post.keyword,
                        purchased_shop: this.post.purchased_shop,
                        purchased_list_price: this.post.purchased_list_price,
                        purchased_price: this.post.purchased_price,
                        purchased_quantity: this.post.purchased_quantity,
                        date_of_purchase: this.post.date_of_purchase,
                        note_web: this.post.note_web,
                        note: this.post.note,
                        major1: +major1,
                        major2: +major2,
                        major3: +major3,
                        major4: +major4,
                        category_minor_cd1: this.post.category_minor_cd1,
                        category_minor_cd2: this.post.category_minor_cd2,
                        category_minor_cd3: this.post.category_minor_cd3,
                        category_minor_cd4: this.post.category_minor_cd4,
                        deleted_at: this.post.deleted_at,
                    });

                    this.editFlg = true;

                    if (this.unregistered) {
                        this.registerdState = '削除済';
                    } else {
                        this.registerdState = '登録済';
                    }
                });
            } else {
                this.mode = 'create';
                this.postCd = null;
                this.editFlg = false;
            }
        });
    }

    onRestore() {
        this.itemService.restoreItem(this.post.cd);
     }

    onSaveItemPost() {
        if (this.form.invalid) {
            return;
        }

        this.isLoading = true;
        if (this.mode === 'create') {
            this.itemService.addItemPost(
                this.form.value.name,
                this.form.value.stock,
                this.form.value.size,
                this.form.value.rental_price,
                this.form.value.shelf_no,
                this.form.value.open_flg,
                this.form.value.keyword,
                this.form.value.purchased_shop,
                this.form.value.purchased_list_price,
                this.form.value.purchased_price,
                this.form.value.purchased_quantity,
                this.form.value.date_of_purchase,
                this.form.value.note_web,
                this.form.value.note,
                this.form.value.category_minor_cd1,
                this.form.value.category_minor_cd2,
                this.form.value.category_minor_cd3,
                this.form.value.category_minor_cd4,
            );
        } else {
            this.itemService.updateItemPost(
                this.postCd,
                this.form.value.name,
                this.form.value.stock,
                this.form.value.size,
                this.form.value.rental_price,
                this.form.value.shelf_no,
                this.form.value.open_flg,
                this.form.value.keyword,
                this.form.value.purchased_shop,
                this.form.value.purchased_list_price,
                this.form.value.purchased_price,
                this.form.value.purchased_quantity,
                this.form.value.date_of_purchase,
                this.form.value.note_web,
                this.form.value.note,
                this.form.value.category_minor_cd1,
                this.form.value.category_minor_cd2,
                this.form.value.category_minor_cd3,
                this.form.value.category_minor_cd4,
            );
        }
        this.form.reset();
        this.router.navigate(['item-list']);
    }


    goBack(): void {
        this.location.back();
    }

    // カテゴリの大項目のselectboxに変更があると、該当の中項目データだけを抽出。
    majorChangeAction(major, index) {
        let dropDownData;

        // majorが数値でない場合
        if (isNaN(major)) {
            dropDownData = this.categories.find((data: any) => data.id === major._value);

        // majorが数値の場合（テンプレート経由の場合）
        } else {
            dropDownData = this.categories.find((data: any) => data.id === +major);
        }

        if (dropDownData) {
            switch (index) {
                case 1:
                    this.middleCatList1 = dropDownData.MtCategoryMiddles;
                    break;
                case 2:
                    this.middleCatList2 = dropDownData.MtCategoryMiddles;
                    break;
                case 3:
                    this.middleCatList3 = dropDownData.MtCategoryMiddles;
                    break;
                case 4:
                    this.middleCatList4 = dropDownData.MtCategoryMiddles;
                    break;
            }
        }
    }

    ngOnDestroy() {
        this.authStatusSub.unsubscribe();
        this.categoriesSub.unsubscribe();
        if (this.minorSub) {
            this.minorSub.unsubscribe();
        }
    }
}
