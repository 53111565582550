import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material.module';
import { RouterModule } from '@angular/router';

import { CmsRougingModule } from './cms-routing.module';

import { CompanyCreateComponent } from './company-create/company-create.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { CustomerCreateComponent } from './customer-create/customer-create.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CompanySearchComponent } from './company-search/company-search.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { NgxYubinBangoModule } from 'ngx-yubinbango';

@NgModule({
    declarations: [
        CompanyCreateComponent,
        CompanyListComponent,
        CustomerCreateComponent,
        CustomerListComponent,
        CompanySearchComponent,
        CompanyDetailComponent,
        CustomerDetailComponent,
    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        CmsRougingModule,
        NgxYubinBangoModule,
    ],
    exports: [],
})
export class CmsModule {}
