import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../core/services/role-guard.service';
import { AuthGuard } from '../auth/auth.guard';
import { CompanyCreateComponent } from './company-create/company-create.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { CustomerCreateComponent } from './customer-create/customer-create.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

const routes: Routes = [
    { path: '', redirectTo: 'cms/customer-list', pathMatch: 'full' },
    {
        path: 'customer-create',
        component: CustomerCreateComponent,
        data: { role: ['admin', 'editor'] },
        canActivate: [RoleGuard],
    },
    {
        path: 'customer-list',
        component: CustomerListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'customer-edit/:postId',
        component: CustomerCreateComponent,
        canActivate: [RoleGuard],
        data: { role: ['admin', 'editor'] },
    },
    {
        path: 'customer-detail/:postId',
        component: CustomerDetailComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'company-create',
        component: CompanyCreateComponent,
        data: { role: ['admin', 'editor'] },
        canActivate: [RoleGuard],
    },
    { path: 'company-list', component: CompanyListComponent, canActivate: [AuthGuard] },
    {
        path: 'company-edit/:postCd',
        component: CompanyCreateComponent,
        canActivate: [RoleGuard],
        data: { role: ['admin', 'editor'] },
    },
    {
        path: 'company-detail/:postCd',
        component: CompanyDetailComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [],
    imports: [CommonModule, CommonModule, RouterModule.forChild(routes), HttpClientModule, HttpClientJsonpModule],
})
export class CmsRougingModule {}
