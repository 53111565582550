import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material.module';
import { RouterModule } from '@angular/router';
import { OrderRoutingModule } from './order-routing.module';

import { OrderCreateComponent } from './order-create/order-create.component';
import { OrderListComponent } from './order-list/order-list.component';



@NgModule({
    declarations: [
        OrderCreateComponent,
        OrderListComponent
    ],
    imports: [
        CommonModule,
        OrderRoutingModule, 
        AngularMaterialModule, 
        RouterModule, 
        ReactiveFormsModule, 
        FormsModule, 
    ]
})
export class OrderModule { }
