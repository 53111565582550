import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Category } from '../../category/category.model';
import { Major } from '../../category/major.model';
import { stringify } from '@angular/compiler/src/util';

const ITEM_BACKEND_URL = environment.apiUrl + '/category/';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    private categories:Category[] = [];
    private categoriesUpdated = new Subject();
    private breadCrumbUpdated = new Subject();
    private minorUpdated = new Subject();

    constructor(private http: HttpClient) { }
        
    // カテゴリー取得
    getCategories() {
        this.http.get<{ message: string; categories }>(ITEM_BACKEND_URL).subscribe((categoryData) => {
            this.categories = categoryData.categories;
            this.categoriesUpdated.next([...this.categories]);
        });
    }
    
    getCategoriesUpdateListener() {
        return this.categoriesUpdated.asObservable();
    }
    
    // パンくず用データ取得
    getCatBreadcrumb(minorCd: number) {
        this.http.get<{message: string; catBreadcrumbData}>(ITEM_BACKEND_URL + 'cat-breadcrumb/' + minorCd).subscribe(result => {
            this.breadCrumbUpdated.next(result.catBreadcrumbData);
        });
    }
    
    getBreadCrumbUpdateListener() {
        return this.breadCrumbUpdated.asObservable();
    }

    // minor情報取得
    getMinor(minorCd: number) {
        this.http.get<{message: string; catMinorData}>(ITEM_BACKEND_URL + 'cat-minor/' + minorCd).subscribe(result => {
            this.minorUpdated.next(result.catMinorData);
        })
    }

    
    getMinorUpdateListener() {
        return this.minorUpdated.asObservable();
    }

}
