import { Component, OnInit, OnDestroy } from '@angular/core';
import { RelatedItemService } from '../../core/services/related-item.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { RelatedItem } from '../related-item.model';

@Component({
  selector: 'app-related-item-create',
  templateUrl: './related-item-create.component.html',
  styleUrls: ['./related-item-create.component.scss']
})
export class RelatedItemCreateComponent implements OnInit, OnDestroy {

    constructor(
        private location: Location,
        private authService: AuthService,
        private relatedItemService: RelatedItemService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    mode = 'create';
    editFlg;
    id: number;
    post: RelatedItem;
    unregistered = false;
    isLoading = false;
    form: FormGroup;
    registerdState;
    private authStatusSub: Subscription;

    ngOnInit(): void {
        this.authStatusSub = this.authService.getAuthStatusListener().subscribe((authStatus) => {
            this.isLoading = false;
        });

        this.form = new FormGroup({
            id: new FormControl('未採番', []),
            item_cd1: new FormControl(null, [
                Validators.required, 
                Validators.max(99999)
            ]),
            item_cd2: new FormControl(null, [
                Validators.required, 
                Validators.max(99999)
            ]),
            item_cd3: new FormControl(null, Validators.max(99999)),
            item_cd4: new FormControl(null, Validators.max(99999)),
            item_cd5: new FormControl(null, Validators.max(99999)),
            item_cd6: new FormControl(null, Validators.max(99999)),
            item_cd7: new FormControl(null, Validators.max(99999)),
            item_cd8: new FormControl(null, Validators.max(99999)),
            item_cd9: new FormControl(null, Validators.max(99999)),
            item_cd10: new FormControl(null, Validators.max(99999)),
            item_cd11: new FormControl(null, Validators.max(99999)),
            item_cd12: new FormControl(null, Validators.max(99999)),
            item_cd13: new FormControl(null, Validators.max(99999)),
            item_cd14: new FormControl(null, Validators.max(99999)),
            item_cd15: new FormControl(null, Validators.max(99999)),
            item_cd16: new FormControl(null, Validators.max(99999)),
            item_cd17: new FormControl(null, Validators.max(99999)),
            item_cd18: new FormControl(null, Validators.max(99999)),
            item_cd19: new FormControl(null, Validators.max(99999)),
            item_cd20: new FormControl(null, Validators.max(99999))
        });
        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            if (paramMap.has('id')) {
                this.mode = 'edit';
                this.id = +paramMap.get('id');
                this.isLoading = true;
                this.relatedItemService.getRelatedItem(this.id).subscribe(postData => {
                    this.isLoading = false;
                    
                    if (postData.deleted_at) {
                        this.unregistered = true;
                    }

                    this.post = {
                        id: postData.id,
                        item_cd1: postData.item_cd1,
                        item_cd2: postData.item_cd2,
                        item_cd3: postData.item_cd3,
                        item_cd4: postData.item_cd4,
                        item_cd5: postData.item_cd5,
                        item_cd6: postData.item_cd6,
                        item_cd7: postData.item_cd7,
                        item_cd8: postData.item_cd8,
                        item_cd9: postData.item_cd9,
                        item_cd10: postData.item_cd10,
                        item_cd11: postData.item_cd11,
                        item_cd12: postData.item_cd12,
                        item_cd13: postData.item_cd13,
                        item_cd14: postData.item_cd14,
                        item_cd15: postData.item_cd15,
                        item_cd16: postData.item_cd16,
                        item_cd17: postData.item_cd17,
                        item_cd18: postData.item_cd18,
                        item_cd19: postData.item_cd19,
                        item_cd20: postData.item_cd20,
                    }

                    // FormControlの初期値をnullにしているが、editの場合は値がある。そのためsetValueメソッドを追加し、その値に上書きする。
                    this.form.setValue({
                        id: this.post.id,
                        item_cd1: this.post.item_cd1,
                        item_cd2: this.post.item_cd2,
                        item_cd3: this.post.item_cd3,
                        item_cd4: this.post.item_cd4,
                        item_cd5: this.post.item_cd5,
                        item_cd6: this.post.item_cd6,
                        item_cd7: this.post.item_cd7,
                        item_cd8: this.post.item_cd8,
                        item_cd9: this.post.item_cd9,
                        item_cd10: this.post.item_cd10,
                        item_cd11: this.post.item_cd11,
                        item_cd12: this.post.item_cd12,
                        item_cd13: this.post.item_cd13,
                        item_cd14: this.post.item_cd14,
                        item_cd15: this.post.item_cd15,
                        item_cd16: this.post.item_cd16,
                        item_cd17: this.post.item_cd17,
                        item_cd18: this.post.item_cd18,
                        item_cd19: this.post.item_cd19,
                        item_cd20: this.post.item_cd20,
                    });

                    this.editFlg = true;
                    if (this.unregistered) {
                        this.registerdState = '削除済';
                    } else {
                        this.registerdState = '登録済';
                    }
                })
            } else {
                this.mode = 'create';
                this.id = null;
                this.editFlg = false;
            }
        });
    }

    onRestore() {
        this.relatedItemService.restoreRelatedItem(this.post.id);
     }

    onSaveRelatedItemPost() {
        if (this.form.invalid) {
            return;
        }
        this.isLoading = true;
        if (this.mode === 'create') {
            this.relatedItemService.addRelatedItemPost(
                this.form.value.item_cd1,
                this.form.value.item_cd2,
                this.form.value.item_cd3,
                this.form.value.item_cd4,
                this.form.value.item_cd5,
                this.form.value.item_cd6,
                this.form.value.item_cd7,
                this.form.value.item_cd8,
                this.form.value.item_cd9,
                this.form.value.item_cd10,
                this.form.value.item_cd11,
                this.form.value.item_cd12,
                this.form.value.item_cd13,
                this.form.value.item_cd14,
                this.form.value.item_cd15,
                this.form.value.item_cd16,
                this.form.value.item_cd17,
                this.form.value.item_cd18,
                this.form.value.item_cd19,
                this.form.value.item_cd20
            );
        } else {
            this.relatedItemService.updateRelatedItemPost(
                this.id,
                this.form.value.item_cd1,
                this.form.value.item_cd2,
                this.form.value.item_cd3,
                this.form.value.item_cd4,
                this.form.value.item_cd5,
                this.form.value.item_cd6,
                this.form.value.item_cd7,
                this.form.value.item_cd8,
                this.form.value.item_cd9,
                this.form.value.item_cd10,
                this.form.value.item_cd11,
                this.form.value.item_cd12,
                this.form.value.item_cd13,
                this.form.value.item_cd14,
                this.form.value.item_cd15,
                this.form.value.item_cd16,
                this.form.value.item_cd17,
                this.form.value.item_cd18,
                this.form.value.item_cd19,
                this.form.value.item_cd20
            );
        }
        this.form.reset();
        this.router.navigate(['related-item-list']);
    }

    goBack(): void {
        this.location.back();
    }

    ngOnDestroy() {
        this.authStatusSub.unsubscribe();
    }
}
