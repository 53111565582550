import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../core/services/role-guard.service';
import { AuthGuard } from '../auth/auth.guard';
import { ItemListComponent } from './item-list/item-list.component';
import { ItemCreateComponent } from './item-create/item-create.component';
import { ItemDetailComponent } from './item-detail/item-detail.component';


const routes: Routes = [
    { path: 'item-list', component: ItemListComponent, canActivate: [AuthGuard]},
    { path: 'item-create', component: ItemCreateComponent, canActivate: [RoleGuard], data: { role: ['admin', 'editor'] } },
    { path: 'item-edit/:postCd', component: ItemCreateComponent, canActivate: [RoleGuard], data: { role: ['admin', 'editor'] } },
    { path: 'item-detail/:cd', component: ItemDetailComponent, canActivate: [AuthGuard] },
];



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CommonModule, RouterModule.forChild(routes)
  ]
})
export class ItemRoutingModule { }
