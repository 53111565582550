<section>
    <div class="body">
        <h1 class="section-title" *ngIf="mode == 'create'; else editH1">関連商品登録</h1>
        <ng-template #editH1>
            <h1 class="section-title">関連商品修正</h1>
        </ng-template>
        <form [formGroup]="form" *ngIf="!isLoading" (submit)="onSaveRelatedItemPost()">
            <mat-spinner *ngIf="isLoading"></mat-spinner>
            <div class="card__heading">
                <div *ngIf="editFlg" class="registerd_state" [class.unregistered]="unregistered">
                    {{ registerdState }}
                </div>
            </div>
            <div class="id-wrap">
                <mat-form-field appearance="fill">
                    <mat-label>ID</mat-label>
                    <input matInput type="text" formControlName="id" tabindex="1" readonly />
                </mat-form-field>
            </div>
            <div class="mat-card-wrap">
                <mat-card>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD1</mat-label>
                        <input matInput type="number" formControlName="item_cd1" tabindex="1" />
                        <mat-error *ngIf="form.get('item_cd1').errors?.required">商品CDを入力してください</mat-error>
                        <mat-error *ngIf="form.get('item_cd1').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD2</mat-label>
                        <input matInput type="number" formControlName="item_cd2" tabindex="2" />
                        <mat-error *ngIf="form.get('item_cd2').errors?.required">商品CDを入力してください</mat-error>
                        <mat-error *ngIf="form.get('item_cd2').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD3</mat-label>
                        <input matInput type="number" formControlName="item_cd3" tabindex="3" />
                        <mat-error *ngIf="form.get('item_cd3').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD4</mat-label>
                        <input matInput type="number" formControlName="item_cd4" tabindex="4" />
                        <mat-error *ngIf="form.get('item_cd4').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD5</mat-label>
                        <input matInput type="number" formControlName="item_cd5" tabindex="5" />
                        <mat-error *ngIf="form.get('item_cd5').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD6</mat-label>
                        <input matInput type="number" formControlName="item_cd6" tabindex="6" />
                        <mat-error *ngIf="form.get('item_cd6').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD7</mat-label>
                        <input matInput type="number" formControlName="item_cd7" tabindex="7" />
                        <mat-error *ngIf="form.get('item_cd7').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD8</mat-label>
                        <input matInput type="number" formControlName="item_cd8" tabindex="8" />
                        <mat-error *ngIf="form.get('item_cd8').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD9</mat-label>
                        <input matInput type="number" formControlName="item_cd9" tabindex="9" />
                        <mat-error *ngIf="form.get('item_cd9').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD10</mat-label>
                        <input matInput type="number" formControlName="item_cd10" tabindex="10" />
                        <mat-error *ngIf="form.get('item_cd10').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD11</mat-label>
                        <input matInput type="number" formControlName="item_cd11" tabindex="11" />
                        <mat-error *ngIf="form.get('item_cd11').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD12</mat-label>
                        <input matInput type="number" formControlName="item_cd12" tabindex="12" />
                        <mat-error *ngIf="form.get('item_cd12').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD13</mat-label>
                        <input matInput type="number" formControlName="item_cd13" tabindex="13" />
                        <mat-error *ngIf="form.get('item_cd13').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD14</mat-label>
                        <input matInput type="number" formControlName="item_cd14" tabindex="14" />
                        <mat-error *ngIf="form.get('item_cd14').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD15</mat-label>
                        <input matInput type="number" formControlName="item_cd15" tabindex="15" />
                        <mat-error *ngIf="form.get('item_cd15').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD16</mat-label>
                        <input matInput type="number" formControlName="item_cd16" tabindex="16" />
                        <mat-error *ngIf="form.get('item_cd16').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD17</mat-label>
                        <input matInput type="number" formControlName="item_cd17" tabindex="17" />
                        <mat-error *ngIf="form.get('item_cd17').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD18</mat-label>
                        <input matInput type="number" formControlName="item_cd18" tabindex="18" />
                        <mat-error *ngIf="form.get('item_cd18').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD19</mat-label>
                        <input matInput type="number" formControlName="item_cd19" tabindex="19" />
                        <mat-error *ngIf="form.get('item_cd19').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>商品CD20</mat-label>
                        <input matInput type="number" formControlName="item_cd20" tabindex="20" />
                        <mat-error *ngIf="form.get('item_cd20').errors?.max">商品CDは5桁以内です</mat-error>
                    </mat-form-field>
                </mat-card>
            </div>
            <div class="submit-flex">
                <div class="submit-flex__inner">
                    <button mat-raised-button class="submit-button" color="accent" type="submit">
                        <div *ngIf="mode == 'create'; else edit">登録する</div>
                        <ng-template #edit>
                            <div class="deleted_bg">更新する</div>
                        </ng-template>
                    </button>
                    <a mat-raised-button class="submit-button ml10" color="basic" (click)="goBack()">戻る</a>
                </div>
                <p class="revive-button" *ngIf="unregistered" (click)="onRestore()">復活させる</p>
            </div>
        </form>
    </div>
</section>
