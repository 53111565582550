import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { CategoryService } from '../core/services/category.service';
import { Subscription } from 'rxjs';
import { Category } from './category.model';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {
    categories: Category[] = [];
    private categoriesSub: Subscription;
    objectValues = Object.values;
    objectKeys = Object.keys;
    @Output() categoryCd = new EventEmitter();

    constructor(public categoryService: CategoryService) { }

    ngOnInit(): void {
        this.categoryService.getCategories();
        this.categoriesSub = this.categoryService.getCategoriesUpdateListener().subscribe((categories: Category[]) => {
            this.categories = categories;
        });
    }

    sendCategoryCd(cd) {
        this.categoryCd.emit(cd);
    }

    ngOnDestroy() {
        this.categoriesSub.unsubscribe();
    }

}