<button mat-button [matMenuTriggerFor]="menu">カテゴリー</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="sendCategoryCd(00000)">全て</button>
    <ng-container *ngFor="let category of objectValues(categories)">
        <button mat-menu-item [matMenuTriggerFor]="middleMenu">{{ category.name }}</button>
        <mat-menu #middleMenu="matMenu">
            <ng-container *ngFor="let middle of category.MtCategoryMiddles">
                <button mat-menu-item [matMenuTriggerFor]="minorMenu">
                    {{ middle.name }}
                </button>
                <mat-menu #minorMenu="matMenu">
                    <button
                        *ngFor="let minor of middle.MtCategoryMinors"
                        mat-menu-item
                        (click)="sendCategoryCd(minor.category_minor_cd)"
                    >
                        {{ minor.name }}
                    </button>
                </mat-menu>
            </ng-container>
        </mat-menu>
    </ng-container>
</mat-menu>
