import { CmsService } from '../../core/services/cms.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Company } from '../company.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-company-create',
    templateUrl: './company-create.component.html',
    styleUrls: ['./company-create.component.scss'],
})
export class CompanyCreateComponent implements OnInit, OnDestroy {
    constructor(
        public cmsService: CmsService,
        public route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private location: Location
    ) {}

    mode = 'create';
    editFlg;
    postCd: string;
    post: Company;
    isLoading = false;
    unregistered = false;
    registerdState;
    disabled;
    form: FormGroup;
    private authStatusSub: Subscription;

    closing_days = [
        { value: '都度清算', viewValue: '都度清算' },
        { value: '20日', viewValue: '20日' },
        { value: '現金後日', viewValue: '現金後日' },
        { value: '末日', viewValue: '末日' },
        { value: '不明', viewValue: '不明' },
    ];
    is_company = [
        { value: '法人', viewValue: '法人' },
        { value: '個人', viewValue: '個人' },
    ];

    bills = [
        { value: '郵送', viewValue: '郵送' },
        { value: 'PDF添付', viewValue: 'PDF添付' },
        { value: 'その他', viewValue: 'その他' },
    ];

    ngOnInit() {
        this.authStatusSub = this.authService.getAuthStatusListener().subscribe((authStatus) => {
            this.isLoading = false;
        });
        this.form = new FormGroup({
            cd: new FormControl('未採番', {
                validators: [Validators.required],
            }),
            name: new FormControl(null, {
                validators: [Validators.required, Validators.minLength(2)],
            }),
            name_kana: new FormControl(null, {
                validators: [Validators.required, Validators.minLength(2)],
            }),
            branch_name: new FormControl(null, []),
            zip: new FormControl(null, []),
            prefecture: new FormControl(null, []),
            address: new FormControl(null, []),
            building: new FormControl(null, []),
            room_no: new FormControl(null, []),
            tel: new FormControl(null, []),
            fax: new FormControl(null, []),
            closing_day: new FormControl(null, []),
            bill: new FormControl(null, []),
            is_company: new FormControl(null, []),
            registered_at: new FormControl(null, []),
            note: new FormControl(null, []),
        });
        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            if (paramMap.has('postCd')) {
                this.mode = 'edit';
                this.postCd = paramMap.get('postCd');
                this.isLoading = true;
                this.cmsService.getCompanyPost(this.postCd).subscribe((postData) => {
                    this.isLoading = false;
                    if (postData.deleted_at) {
                        this.unregistered = true;
                    }
                    this.post = {
                        cd: postData.cd,
                        name: postData.name,
                        name_kana: postData.name_kana,
                        branch_name: postData.branch_name,
                        zip: postData.zip,
                        prefecture: postData.prefecture,
                        address: postData.address,
                        building: postData.building,
                        room_no: postData.room_no,
                        tel: postData.tel,
                        fax: postData.fax,
                        closing_day: postData.closing_day,
                        bill: postData.bill,
                        is_company: postData.is_company,
                        registered_at: postData.registered_at,
                        deleted_at: postData.deleted_at,
                        note: postData.note,
                    };
                    // console.log(this.post);

                    // FormControlの初期値をnullにしているが、editの場合は値がある。そのためsetValueメソッドを追加し、その値に上書きする。
                    this.form.setValue({
                        cd: this.post.cd,
                        name: this.post.name,
                        name_kana: this.post.name_kana,
                        branch_name: this.post.branch_name,
                        zip: this.post.zip,
                        prefecture: this.post.prefecture,
                        address: this.post.address,
                        building: this.post.building,
                        room_no: this.post.room_no,
                        tel: this.post.tel,
                        fax: this.post.fax,
                        closing_day: this.post.closing_day,
                        bill: this.post.bill,
                        is_company: this.post.is_company,
                        registered_at: this.post.registered_at,
                        note: this.post.note,
                    });

                    this.editFlg = true;

                    if (this.unregistered) {
                        this.registerdState = '削除済';
                    } else {
                        this.registerdState = '登録済';
                    }
                });
            } else {
                this.mode = 'create';
                this.postCd = null;
                this.editFlg = false;
            }
        });
    }

    onRestore() {
        this.cmsService.restoreCompanyPost(this.post.cd);
    }

    onSaveCompanyPost() {
        if (this.form.invalid) {
            return;
        }

        this.isLoading = true;
        if (this.mode === 'create') {
            this.cmsService.addCompanyPost(
                this.form.value.name,
                this.form.value.name_kana,
                this.form.value.branch_name,
                this.form.value.zip,
                this.form.value.prefecture,
                this.form.value.address,
                this.form.value.building,
                this.form.value.room_no,
                this.form.value.tel,
                this.form.value.fax,
                this.form.value.closing_day,
                this.form.value.bill,
                this.form.value.is_company,
                this.form.value.registered_at,
                this.form.value.creator,
                this.form.value.note
            );
        } else {
            this.cmsService.updateCompanyPost(
                this.postCd,
                this.form.value.name,
                this.form.value.name_kana,
                this.form.value.branch_name,
                this.form.value.zip,
                this.form.value.prefecture,
                this.form.value.address,
                this.form.value.building,
                this.form.value.room_no,
                this.form.value.tel,
                this.form.value.fax,
                this.form.value.closing_day,
                this.form.value.bill,
                this.form.value.is_company,
                this.form.value.registered_at,
                this.form.value.note
            );
        }
        this.form.reset();
        this.router.navigate(['company-list']);
    }

    goBack(): void {
        this.location.back();
    }

    ngOnDestroy() {
        this.authStatusSub.unsubscribe();
    }
}
