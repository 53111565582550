import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthData } from '../../auth/auth-data.model';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

const BACKEND_URL = environment.apiUrl + '/user/';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private isAuthenticated = false;
    private token: string;
    private role: string;
    private tokenTimer: number;
    private authStatusListener = new Subject<boolean>();
    private roleStatusListener = new Subject<string>();
    public userEmail: string;
    private users: AuthData[] = [];
    private usersUpdated = new Subject();

    constructor(private http: HttpClient, private router: Router) {}

    getUsers() {
        this.http.get<{message: string; users:AuthData[]}>(BACKEND_URL + 'users').subscribe(userData => {
            this.users = userData.users;
            this.usersUpdated.next([...this.users]);
        })
    }

    getUsersUpdateListener() {
        return this.usersUpdated.asObservable();
    }

    getToken() {
        return this.token;
    }

    getRole() {
        return this.role;
    }

    getIsAuth() {
        return this.isAuthenticated;
    }

    getRoleData() {
        const role = localStorage.getItem('role');
        return role;
    }

    getAuthStatusListener() {
        return this.authStatusListener.asObservable();
    }

    getRoleStatusListener() {
        return this.roleStatusListener.asObservable();
    }


    createUser(email: string, name: string, password: string, role: string) {
        const authData: AuthData = { email, name, password, role };
        this.http.post(BACKEND_URL + 'signup', authData).subscribe(
            () => {
                this.router.navigate(['/']);
            },
            (error) => {
                this.authStatusListener.next(false);
            }
        );
    }

    login(email: string, password: string) {
        const authData: AuthData = { email, password };
        this.http.post<{ token: string; expiresIn: number; role: string }>(BACKEND_URL + 'login', authData).subscribe(
            (response) => {
                const token = response.token;
                const role = response.role;
                this.token = token;
                this.role = role;
                if (token) {
                    const expiresInDuration = response.expiresIn;
                    this.setAuthTimer(expiresInDuration);
                    this.userEmail = JSON.parse(atob(token.split('.')[1])).email;
                    this.isAuthenticated = true;
                    this.authStatusListener.next(true);
                    this.roleStatusListener.next(this.role);
                    const now = new Date();
                    const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
                    this.saveAuthData(token, expirationDate, role, this.userEmail);
                    this.router.navigate(['/']);
                }
            },
            (error) => {
                this.authStatusListener.next(false);
            }
        );
    }

    // 失効日時が将来もまだあるかどうかをチェック;
    autoAuthUser() {
        const authInformation = this.getAuthData();
        if (!authInformation) {
            return;
        }
        const now = new Date();
        const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
        if (expiresIn > 0) {
            this.token = authInformation.token;
            this.isAuthenticated = true;
            this.userEmail = authInformation.userEmail;
            this.setAuthTimer(expiresIn / 1000);
            this.authStatusListener.next(true);

            // 有効期限が切れてもtokenが消えないので、else文を追加 (2020.05.23)
        } else {
            this.logout();
        }
    }

    logout() {
        this.token = null;
        this.isAuthenticated = false;
        this.role = '';
        this.authStatusListener.next(false);
        this.roleStatusListener.next('');
        clearTimeout(this.tokenTimer);
        this.clearAuthData();
        this.router.navigate(['/auth/login']);
    }

    private clearAuthData() {
        localStorage.removeItem('token');
        localStorage.removeItem('expiration');
        localStorage.removeItem('role');
        localStorage.removeItem('userEmail');
    }

    private setAuthTimer(duration: number) {
        this.tokenTimer = window.setTimeout(() => {
            this.logout();
        }, duration * 1000);
    }

    private saveAuthData(token: string, expirationDate: Date, role: string, userEmail: string) {
        localStorage.setItem('token', token);
        localStorage.setItem('expiration', expirationDate.toISOString());
        localStorage.setItem('role', role);
        localStorage.setItem('userEmail', userEmail);
    }

    private getAuthData() {
        const token = localStorage.getItem('token');
        const expirationDate = localStorage.getItem('expiration');
        const role = localStorage.getItem('role');
        const userEmail = localStorage.getItem('userEmail');
        if (!token || !expirationDate) {
            return;
        }
        return {
            token,
            expirationDate: new Date(expirationDate),
            role,
            userEmail,
        };
    }

    decode() {
        const role = localStorage.getItem('role');
        return role;
    }
}
