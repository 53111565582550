import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    userIsAuthenticated = false;
    userRole;
    private authListenerSubs: Subscription;
    private roleListenerSubs: Subscription;

    constructor(public authService: AuthService, private router: Router) {}

    isCustomerList(): boolean {
        return this.router.url.includes('cms/customer-list');
    }

    isCompanyList(): boolean {
        return this.router.url.includes('cms/company-list');
    }

    ngOnInit() {
        this.userIsAuthenticated = this.authService.getIsAuth();
        this.authListenerSubs = this.authService.getAuthStatusListener().subscribe((isAuthenticated) => {
            this.userIsAuthenticated = isAuthenticated;
        });
        this.userRole = this.authService.getRoleData();
        this.roleListenerSubs = this.authService.getRoleStatusListener().subscribe((role) => {
            this.userRole = role;
        });
        // console.log(this.userRole);
    }

    onLogout() {
        this.authService.logout();
    }

    ngOnDestroy() {
        this.authListenerSubs.unsubscribe();
        this.roleListenerSubs.unsubscribe();
    }
}
