<section class="body">
    <ng-container>
        <!-- スマホ用ヘッダー -->
        <div class="heading">
            <!-- <a routerLink="/company-list" class="heading__link">会社名 ></a> -->
            <h1 class="section-title">登録者一覧</h1>
            <a routerLink="/customer-create" class="heading__add" *ngIf="viewer === false"
                ><img src="assets/img/common/add_icon2.svg"
            /></a>
            <div class="heading__search-block">
                <mat-form-field>
                    <mat-label>絞り込み</mat-label>
                    <input matInput (keyup)="applyFilter($event)" (click)="moveTab()" placeholder="例）福元" />
                </mat-form-field>
                <div class="heading__post_count">
                    {{ post_count }}/<span>{{ current_count }}件</span>
                </div>
            </div>
            <mat-checkbox
                labelPosition="before"
                class="heading__search-option"
                [(ngModel)]="checked"
                (change)="onAddFilter($event)"
                color="primary"
                >削除済も表示</mat-checkbox
            >
        </div>

        <!-- PC用ヘッダー -->
        <div class="heading-pc">
            <h1 class="section-title">登録者一覧</h1>
            <div class="heading-pc__search-block">
                <mat-form-field>
                    <mat-label>絞り込み</mat-label>
                    <input matInput (keyup)="applyFilter($event)" (click)="moveTab()" placeholder="例）福元" />
                </mat-form-field>
                <mat-checkbox
                    labelPosition="before"
                    class="heading-pc__search-option"
                    [(ngModel)]="checked"
                    (change)="onAddFilter($event)"
                    color="primary"
                    >削除済も表示</mat-checkbox
                >
                <div class="heading-pc__post_count">
                    {{ post_count }}/<span>{{ current_count }}件</span>
                </div>
            </div>
            <a routerLink="/customer-create" class="heading-pc__btn secondary-button" *ngIf="viewer === false"
                >登録者追加</a
            >
        </div>

        <mat-spinner *ngIf="isLoading"></mat-spinner>

        <mat-tab-group animationDuration="0ms" *ngIf="!isLoading" [(selectedIndex)]="firstTab">
            <mat-tab label="新着順">
                <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="cd">
                        <mat-header-cell *matHeaderCellDef>登録者CD</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div *ngIf="element.deleted_at === null; else deleted_block">{{ element.cd }}</div>
                            <ng-template #deleted_block>
                                <div class="deleted_bg">{{ element.cd }}</div>
                            </ng-template>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>登録者名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="TbCompany">
                        <mat-header-cell *matHeaderCellDef>会社名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.TbCompany.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef>メール</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mobile_phone">
                        <mat-header-cell *matHeaderCellDef>携帯電話</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.mobile_phone }}</mat-cell>
                    </ng-container>

                    <ng-container *ngIf="viewer === false; else not_login">
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon">
                                <ul class="icon__list">
                                    <!-- <li class="icon__item">
                                        <a title="出荷"><i class="icon__order"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a title="履歴"><i class="icon__history"></i></a>
                                    </li> -->
                                    <li class="icon__item">
                                        <a [routerLink]="['/customer-edit', element.id]" title="編集"
                                            ><i class="icon__edit"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at === null" class="icon__item">
                                        <a (click)="onCustomerDelete(element.id)" title="削除"
                                            ><i class="icon__delete"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at !== null" class="icon__item">
                                        <i
                                            class="icon__delete"
                                            style="cursor: default; pointer-events: none"
                                            title="削除"
                                        ></i>
                                    </li>
                                </ul>
                            </mat-cell>
                        </ng-container>
                    </ng-container>
                    <ng-template #not_login>
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon"></mat-cell>
                        </ng-container>
                    </ng-template>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        [ngClass]="{ deleted_bg: row.deleted_at !== null }"
                    ></mat-row>
                </mat-table>
            </mat-tab>
            <mat-tab label="あ">
                <mat-table [dataSource]="dataSource_a" class="mat-elevation-z8">
                    <ng-container matColumnDef="cd">
                        <mat-header-cell *matHeaderCellDef>登録者CD</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.cd }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>登録者名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="TbCompany">
                        <mat-header-cell *matHeaderCellDef>会社名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.TbCompany.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef>メール</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mobile_phone">
                        <mat-header-cell *matHeaderCellDef>携帯電話</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.mobile_phone }}</mat-cell>
                    </ng-container>

                    <ng-container *ngIf="viewer === false; else not_login_a">
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon">
                                <ul class="icon__list">
                                    <li class="icon__item">
                                        <a title="出荷"><i class="icon__order"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a title="履歴"><i class="icon__history"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a [routerLink]="['/customer-edit', element.id]" title="編集"
                                            ><i class="icon__edit"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at === null" class="icon__item">
                                        <a (click)="onCustomerDelete(element.id)" title="削除"
                                            ><i class="icon__delete"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at !== null" class="icon__item">
                                        <i
                                            class="icon__delete"
                                            style="cursor: default; pointer-events: none"
                                            title="削除"
                                        ></i>
                                    </li>
                                </ul>
                            </mat-cell>
                        </ng-container>
                    </ng-container>
                    <ng-template #not_login_a>
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon"></mat-cell>
                        </ng-container>
                    </ng-template>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        [ngClass]="{ deleted_bg: row.deleted_at !== null }"
                    ></mat-row>
                </mat-table>
            </mat-tab>
            <mat-tab label="か">
                <mat-table [dataSource]="dataSource_ka" class="mat-elevation-z8">
                    <ng-container matColumnDef="cd">
                        <mat-header-cell *matHeaderCellDef>登録者CD</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.cd }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>登録者名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="TbCompany">
                        <mat-header-cell *matHeaderCellDef>会社名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.TbCompany.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef>メール</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mobile_phone">
                        <mat-header-cell *matHeaderCellDef>携帯電話</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.mobile_phone }}</mat-cell>
                    </ng-container>

                    <ng-container *ngIf="viewer === false; else not_login_ka">
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon">
                                <ul class="icon__list">
                                    <li class="icon__item">
                                        <a title="出荷"><i class="icon__order"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a title="履歴"><i class="icon__history"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a [routerLink]="['/customer-edit', element.id]" title="編集"
                                            ><i class="icon__edit"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at === null" class="icon__item">
                                        <a (click)="onCustomerDelete(element.id)" title="削除"
                                            ><i class="icon__delete"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at !== null" class="icon__item">
                                        <i
                                            class="icon__delete"
                                            style="cursor: default; pointer-events: none"
                                            title="削除"
                                        ></i>
                                    </li>
                                </ul>
                            </mat-cell>
                        </ng-container>
                    </ng-container>
                    <ng-template #not_login_ka>
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon"></mat-cell>
                        </ng-container>
                    </ng-template>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        [ngClass]="{ deleted_bg: row.deleted_at !== null }"
                    ></mat-row>
                </mat-table>
            </mat-tab>
            <mat-tab label="さ">
                <mat-table [dataSource]="dataSource_sa" class="mat-elevation-z8">
                    <ng-container matColumnDef="cd">
                        <mat-header-cell *matHeaderCellDef>登録者CD</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.cd }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>登録者名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="TbCompany">
                        <mat-header-cell *matHeaderCellDef>会社名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.TbCompany.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef>メール</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mobile_phone">
                        <mat-header-cell *matHeaderCellDef>携帯電話</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.mobile_phone }}</mat-cell>
                    </ng-container>

                    <ng-container *ngIf="viewer === false; else not_login_sa">
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon">
                                <ul class="icon__list">
                                    <li class="icon__item">
                                        <a title="出荷"><i class="icon__order"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a title="履歴"><i class="icon__history"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a [routerLink]="['/customer-edit', element.id]" title="編集"
                                            ><i class="icon__edit"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at === null" class="icon__item">
                                        <a (click)="onCustomerDelete(element.id)" title="削除"
                                            ><i class="icon__delete"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at !== null" class="icon__item">
                                        <i
                                            class="icon__delete"
                                            style="cursor: default; pointer-events: none"
                                            title="削除"
                                        ></i>
                                    </li>
                                </ul>
                            </mat-cell>
                        </ng-container>
                    </ng-container>
                    <ng-template #not_login_sa>
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon"></mat-cell>
                        </ng-container>
                    </ng-template>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        [ngClass]="{ deleted_bg: row.deleted_at !== null }"
                    ></mat-row>
                </mat-table>
            </mat-tab>
            <mat-tab label="た">
                <mat-table [dataSource]="dataSource_ta" class="mat-elevation-z8">
                    <ng-container matColumnDef="cd">
                        <mat-header-cell *matHeaderCellDef>登録者CD</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.cd }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>登録者名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="TbCompany">
                        <mat-header-cell *matHeaderCellDef>会社名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.TbCompany.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef>メール</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mobile_phone">
                        <mat-header-cell *matHeaderCellDef>携帯電話</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.mobile_phone }}</mat-cell>
                    </ng-container>

                    <ng-container *ngIf="viewer === false; else not_login_ta">
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon">
                                <ul class="icon__list">
                                    <li class="icon__item">
                                        <a title="出荷"><i class="icon__order"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a title="履歴"><i class="icon__history"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a [routerLink]="['/customer-edit', element.id]" title="編集"
                                            ><i class="icon__edit"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at === null" class="icon__item">
                                        <a (click)="onCustomerDelete(element.id)" title="削除"
                                            ><i class="icon__delete"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at !== null" class="icon__item">
                                        <i
                                            class="icon__delete"
                                            style="cursor: default; pointer-events: none"
                                            title="削除"
                                        ></i>
                                    </li>
                                </ul>
                            </mat-cell>
                        </ng-container>
                    </ng-container>
                    <ng-template #not_login_ta>
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon"></mat-cell>
                        </ng-container>
                    </ng-template>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        [ngClass]="{ deleted_bg: row.deleted_at !== null }"
                    ></mat-row>
                </mat-table>
            </mat-tab>
            <mat-tab label="な">
                <mat-table [dataSource]="dataSource_na" class="mat-elevation-z8">
                    <ng-container matColumnDef="cd">
                        <mat-header-cell *matHeaderCellDef>登録者CD</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.cd }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>登録者名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="TbCompany">
                        <mat-header-cell *matHeaderCellDef>会社名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.TbCompany.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef>メール</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mobile_phone">
                        <mat-header-cell *matHeaderCellDef>携帯電話</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.mobile_phone }}</mat-cell>
                    </ng-container>

                    <ng-container *ngIf="viewer === false; else not_login_na">
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon">
                                <ul class="icon__list">
                                    <li class="icon__item">
                                        <a title="出荷"><i class="icon__order"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a title="履歴"><i class="icon__history"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a [routerLink]="['/customer-edit', element.id]" title="編集"
                                            ><i class="icon__edit"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at === null" class="icon__item">
                                        <a (click)="onCustomerDelete(element.id)" title="削除"
                                            ><i class="icon__delete"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at !== null" class="icon__item">
                                        <i
                                            class="icon__delete"
                                            style="cursor: default; pointer-events: none"
                                            title="削除"
                                        ></i>
                                    </li>
                                </ul>
                            </mat-cell>
                        </ng-container>
                    </ng-container>
                    <ng-template #not_login_na>
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon"></mat-cell>
                        </ng-container>
                    </ng-template>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        [ngClass]="{ deleted_bg: row.deleted_at !== null }"
                    ></mat-row>
                </mat-table>
            </mat-tab>
            <mat-tab label="は">
                <mat-table [dataSource]="dataSource_ha" class="mat-elevation-z8">
                    <ng-container matColumnDef="cd">
                        <mat-header-cell *matHeaderCellDef>登録者CD</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.cd }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>登録者名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="TbCompany">
                        <mat-header-cell *matHeaderCellDef>会社名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.TbCompany.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef>メール</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mobile_phone">
                        <mat-header-cell *matHeaderCellDef>携帯電話</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.mobile_phone }}</mat-cell>
                    </ng-container>

                    <ng-container *ngIf="viewer === false; else not_login_ha">
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon">
                                <ul class="icon__list">
                                    <li class="icon__item">
                                        <a title="出荷"><i class="icon__order"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a title="履歴"><i class="icon__history"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a [routerLink]="['/customer-edit', element.id]" title="編集"
                                            ><i class="icon__edit"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at === null" class="icon__item">
                                        <a (click)="onCustomerDelete(element.id)" title="削除"
                                            ><i class="icon__delete"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at !== null" class="icon__item">
                                        <i
                                            class="icon__delete"
                                            style="cursor: default; pointer-events: none"
                                            title="削除"
                                        ></i>
                                    </li>
                                </ul>
                            </mat-cell>
                        </ng-container>
                    </ng-container>
                    <ng-template #not_login_ha>
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon"></mat-cell>
                        </ng-container>
                    </ng-template>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        [ngClass]="{ deleted_bg: row.deleted_at !== null }"
                    ></mat-row>
                </mat-table>
            </mat-tab>
            <mat-tab label="ま">
                <mat-table [dataSource]="dataSource_ma" class="mat-elevation-z8">
                    <ng-container matColumnDef="cd">
                        <mat-header-cell *matHeaderCellDef>登録者CD</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.cd }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>登録者名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="TbCompany">
                        <mat-header-cell *matHeaderCellDef>会社名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.TbCompany.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef>メール</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mobile_phone">
                        <mat-header-cell *matHeaderCellDef>携帯電話</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.mobile_phone }}</mat-cell>
                    </ng-container>

                    <ng-container *ngIf="viewer === false; else not_login_ma">
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon">
                                <ul class="icon__list">
                                    <li class="icon__item">
                                        <a title="出荷"><i class="icon__order"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a title="履歴"><i class="icon__history"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a [routerLink]="['/customer-edit', element.id]" title="編集"
                                            ><i class="icon__edit"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at === null" class="icon__item">
                                        <a (click)="onCustomerDelete(element.id)" title="削除"
                                            ><i class="icon__delete"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at !== null" class="icon__item">
                                        <i
                                            class="icon__delete"
                                            style="cursor: default; pointer-events: none"
                                            title="削除"
                                        ></i>
                                    </li>
                                </ul>
                            </mat-cell>
                        </ng-container>
                    </ng-container>
                    <ng-template #not_login_ma>
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon"></mat-cell>
                        </ng-container>
                    </ng-template>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        [ngClass]="{ deleted_bg: row.deleted_at !== null }"
                    ></mat-row>
                </mat-table>
            </mat-tab>
            <mat-tab label="や・ら・わ">
                <mat-table [dataSource]="dataSource_ya" class="mat-elevation-z8">
                    <ng-container matColumnDef="cd">
                        <mat-header-cell *matHeaderCellDef>登録者CD</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.cd }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>登録者名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="TbCompany">
                        <mat-header-cell *matHeaderCellDef>会社名</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a [routerLink]="['/customer-detail', element.id]" class="detail-link">
                                {{ element.TbCompany.name }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef>メール</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mobile_phone">
                        <mat-header-cell *matHeaderCellDef>携帯電話</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.mobile_phone }}</mat-cell>
                    </ng-container>

                    <ng-container *ngIf="viewer === false; else not_login_ya">
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon">
                                <ul class="icon__list">
                                    <li class="icon__item">
                                        <a title="出荷"><i class="icon__order"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a title="履歴"><i class="icon__history"></i></a>
                                    </li>
                                    <li class="icon__item">
                                        <a [routerLink]="['/customer-edit', element.id]" title="編集"
                                            ><i class="icon__edit"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at === null" class="icon__item">
                                        <a (click)="onCustomerDelete(element.id)" title="削除"
                                            ><i class="icon__delete"></i
                                        ></a>
                                    </li>
                                    <li *ngIf="element.deleted_at !== null" class="icon__item">
                                        <i
                                            class="icon__delete"
                                            style="cursor: default; pointer-events: none"
                                            title="削除"
                                        ></i>
                                    </li>
                                </ul>
                            </mat-cell>
                        </ng-container>
                    </ng-container>
                    <ng-template #not_login_ya>
                        <ng-container matColumnDef="icon">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="icon"></mat-cell>
                        </ng-container>
                    </ng-template>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        [ngClass]="{ deleted_bg: row.deleted_at !== null }"
                    ></mat-row>
                </mat-table>
            </mat-tab>

            <p class="info-text mat-body-1" *ngIf="posts.length <= 0">登録件数はゼロです。</p>
        </mat-tab-group>
        <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
    </ng-container>
</section>
