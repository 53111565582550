<div class="site">
    <app-header></app-header>
    <div class="site-body">
        <!-- パソコン版サイドナビ -->
        <!-- <app-sidenav class="sidenav-pc"></app-sidenav> -->
        <div class="site-content">
            <mat-sidenav-container (backdropClick)="close()">
                <!-- スマホ版サイドナビ -->
                <mat-sidenav class="sidenav-sp" #sidenav (keydown.escape)="close()" disableClose>
                    <!-- <app-sidenav>と同じもの。
                    ハンバーガーメニューでリスト内をクリックした時に、サイドナビが消えてくれないのでやむなくここにべた書きしてる -->
                    <div class="user-name">anvi@anvi-osaka.com</div>
                    <nav>
                        <ul class="menu" (click)="close()">
                            <li class="menu__group">
                                <div
                                    class="menu__group-title customer-icon"
                                    routerLinkActive="active"
                                    routerLink="cms/"
                                >
                                    顧客管理
                                </div>
                                <ul class="menu__group-list">
                                    <li class="menu__item">
                                        <a routerLink="cms/customer-list" class="menu__link" routerLinkActive="active"
                                            >登録者</a
                                        >
                                    </li>
                                    <li class="menu__item">
                                        <a routerLink="cms/company-list" class="menu__link" routerLinkActive="active"
                                            >会社名</a
                                        >
                                    </li>
                                </ul>
                            </li>
                            <li class="menu__group">
                                <div class="menu__group-title item-icon" routerLinkActive="active" routerLink="item/">
                                    商品管理
                                </div>
                                <ul class="menu__group-list">
                                    <li class="menu__item">
                                        <a routerLink="/item/item-list" class="menu__link" routerLinkActive="active"
                                            >商品</a
                                        >
                                    </li>
                                    <li class="menu__item">
                                        <a
                                            routerLink="/related-item/related-item-list"
                                            class="menu__link"
                                            routerLinkActive="active"
                                            >関連商品</a
                                        >
                                    </li>
                                </ul>
                            </li>
                            <li class="menu__group">
                                <div class="menu__group-title order-icon" routerLinkActive="active" routerLink="item/">
                                    注文管理
                                </div>
                                <ul class="menu__group-list">
                                    <li class="menu__item">
                                        <a routerLink="/order/order-list" class="menu__link" routerLinkActive="active"
                                            >注文</a
                                        >
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <button class="logout" mat-menu-item (click)="onLogout()">ログアウト</button>
                    </nav>
                </mat-sidenav>
                <mat-sidenav-content>
                    <main>
                        <div class="button-wrap">
                            <div class="btn-trigger" id="btn02" (click)="sidenav.open()">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <router-outlet></router-outlet>
                    </main>
                </mat-sidenav-content>
            </mat-sidenav-container>
        </div>
    </div>
</div>
