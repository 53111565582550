import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { OrderService } from 'src/app/core/services/order.service';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { AuthData } from 'src/app/auth/auth-data.model';

// datepickerの日本語対応
// https://qiita.com/chibi929/items/3577a8b0a2d751dc6bb6
class MyDateAdapter extends NativeDateAdapter {
    getDateNames(): string[] {
        const dateNames: string[] = [];
        for (let i = 0; i < 31; i++) {
            dateNames[i] = String(i + 1);
        }
        return dateNames;
    }
}

@Component({
  selector: 'app-order-create',
  templateUrl: './order-create.component.html',
  styleUrls: ['./order-create.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MyDateAdapter}
    ]
})
export class OrderCreateComponent implements OnInit, OnDestroy {

    constructor(
        private location: Location,
        private authService: AuthService,
        private orderService: OrderService,
        private route: ActivatedRoute,
        private router: Router,
        dateAdapter: DateAdapter<NativeDateAdapter>
    ) {
        dateAdapter.setLocale('ja');
    }

    way_of_order = [
        { value: '来店', viewValue: '来店' },
        { value: 'ネット', viewValue: 'ネット' },
        { value: '電話', viewValue: '電話' },
        { value: 'その他', viewValue: 'その他' },
    ];

    way_to_ship = [
        { value: '来店', viewValue: '来店' },
        { value: '宅急便', viewValue: '宅急便' },
        { value: '運送屋', viewValue: '運送屋' },
        { value: 'その他', viewValue: 'その他' },
    ];

    mode = 'create';
    editFlg;
    orderNum: string;
    isLoading = false;
    form: FormGroup;
    users;
    unregistered = false;
    private authStatusSub: Subscription;
    private usersSub: Subscription;

    ngOnInit(): void {
        this.authStatusSub = this.authService.getAuthStatusListener().subscribe((authStatus) => {
            this.isLoading = false;
        });
        
        this.authService.getUsers();
        this.usersSub = this.authService.getUsersUpdateListener().subscribe(users => {
            this.users = users;
        });

        this.form = new FormGroup({
            order_number: new FormControl(null, []),
            customer_name: new FormControl(null, []),
            customer_cd: new FormControl(null, []),
            orderd_date: new FormControl(null, []),
            expected_shipping_day: new FormControl(null, []),
            returning_scheduled_date: new FormControl(null, []),
            all_returned_date: new FormControl(null, []),
            how_to_order: new FormControl(null, []),
            how_to_ship: new FormControl(null, []),
            user_id: new FormControl(null, []),
            note: new FormControl(null, []),
            print_note: new FormControl(null, []),
            message: new FormControl(null, []),
        });
        // this.route.paramMap.subscribe((paramMap: ParamMap) => {
        //     if (paramMap.has('orderNum')) {
        //         this.mode = 'edit';
        //         this.orderNum = paramMap.get('orderNum');
        //         this.isLoading = true;
                // this.itemService.getItemPost(this.postCd).subscribe(postData => {
                //     this.isLoading = false;

                //     if (postData.deleted_at) {
                //         this.unregistered = true;
                //     }

                //     this.post = {
                //         cd: postData.cd,
                //         name: postData.name,
                //         stock: postData.stock,
                //         size: postData.size,
                //         rental_price: postData.rental_price,
                //         shelf_no: postData.shelf_no,
                //         open_flg: postData.open_flg,
                //         keyword: postData.keyword,
                //         purchased_shop: postData.purchased_shop,
                //         purchased_list_price: postData.purchased_list_price,
                //         purchased_price: postData.purchased_price,
                //         purchased_quantity: postData.purchased_quantity,
                //         date_of_purchase: postData.date_of_purchase,
                //         note_web: postData.note_web,
                //         note: postData.note,
                //         category_minor_cd1: postData.category_minor_cd1,
                //         category_minor_cd2: postData.category_minor_cd2,
                //         category_minor_cd3: postData.category_minor_cd3,
                //         category_minor_cd4: postData.category_minor_cd4,
                //         deleted_at: postData.deleted_at,
                //     };

                //     // major_cdを抽出（minor_cdの先頭文字のみを抽出）
                //     const major1String = '' + this.post.category_minor_cd1;
                //     const major1 = major1String.slice(0, 1);
                //     const major2String = '' + this.post.category_minor_cd2;
                //     const major2 = major2String.slice(0, 1);
                //     const major3String = '' + this.post.category_minor_cd3;
                //     const major3 = major3String.slice(0, 1);
                //     const major4String = '' + this.post.category_minor_cd4;
                //     const major4 = major4String.slice(0, 1);

                //     this.majorChangeAction(major1, 1);
                //     this.majorChangeAction(major2, 2);
                //     this.majorChangeAction(major3, 3);
                //     this.majorChangeAction(major4, 4);


                //     // FormControlの初期値をnullにしているが、editの場合は値がある。そのためsetValueメソッドを追加し、その値に上書きする。
                //     this.form.setValue({
                //         cd: this.post.cd,
                //         name: this.post.name,
                //         stock: this.post.stock,
                //         size: this.post.size,
                //         rental_price: this.post.rental_price,
                //         shelf_no: this.post.shelf_no,
                //         open_flg: this.post.open_flg,
                //         keyword: this.post.keyword,
                //         purchased_shop: this.post.purchased_shop,
                //         purchased_list_price: this.post.purchased_list_price,
                //         purchased_price: this.post.purchased_price,
                //         purchased_quantity: this.post.purchased_quantity,
                //         date_of_purchase: this.post.date_of_purchase,
                //         note_web: this.post.note_web,
                //         note: this.post.note,
                //         major1: +major1,
                //         major2: +major2,
                //         major3: +major3,
                //         major4: +major4,
                //         category_minor_cd1: this.post.category_minor_cd1,
                //         category_minor_cd2: this.post.category_minor_cd2,
                //         category_minor_cd3: this.post.category_minor_cd3,
                //         category_minor_cd4: this.post.category_minor_cd4,
                //         deleted_at: this.post.deleted_at,
                //     });

                //     this.editFlg = true;

                //     if (this.unregistered) {
                //         this.registerdState = '削除済';
                //     } else {
                //         this.registerdState = '登録済';
                //     }
                // });
        //     } else {
                this.mode = 'create';
                this.orderNum = null;
                this.editFlg = false;
        //     }
        // });
    }

    onSaveOrderPost() {
        if (this.form.invalid) {
            return;
        }

        this.isLoading = true;
        if (this.mode === 'create') {
            this.orderService.addOrderPost(
                this.form.value.order_number,
                this.form.value.customer_name,
                this.form.value.customer_cd,
                this.form.value.orderd_date,
                this.form.value.expected_shipping_day,
                this.form.value.returning_scheduled_date,
                this.form.value.all_returned_date,
                this.form.value.how_to_order,
                this.form.value.how_to_ship,
                this.form.value.user_id,
                this.form.value.note,
                this.form.value.print_note,
                this.form.value.message,
            );
        } else {
            // this.itemService.updateItemPost(
            //     this.postCd,
            //     this.form.value.name,
            //     this.form.value.stock,
            //     this.form.value.size,
            //     this.form.value.rental_price,
            //     this.form.value.shelf_no,
            //     this.form.value.open_flg,
            //     this.form.value.keyword,
            //     this.form.value.purchased_shop,
            //     this.form.value.purchased_list_price,
            //     this.form.value.purchased_price,
            //     this.form.value.purchased_quantity,
            //     this.form.value.date_of_purchase,
            //     this.form.value.note_web,
            //     this.form.value.note,
            //     this.form.value.category_minor_cd1,
            //     this.form.value.category_minor_cd2,
            //     this.form.value.category_minor_cd3,
            //     this.form.value.category_minor_cd4,
            // );
        }
        this.form.reset();
        // this.router.navigate(['item-list']);
    }

    goBack(): void {
        this.location.back();
    }

    ngOnDestroy() {
        this.authStatusSub.unsubscribe();
        this.usersSub.unsubscribe();
    }
}
