<section>
    <div class="body">
        <h1 class="section-title" *ngIf="mode == 'create'; else editH1">会社名登録</h1>
        <ng-template #editH1>
            <h1 class="section-title">会社名修正</h1>
        </ng-template>
        <mat-card>
            <mat-spinner *ngIf="isLoading"></mat-spinner>
            <form [formGroup]="form" (submit)="onSaveCompanyPost()" ybAddress *ngIf="!isLoading">
                <div class="card__heading">
                    <div *ngIf="editFlg" class="registerd_state" [class.unregistered]="unregistered">
                        {{ registerdState }}
                    </div>
                </div>

                <!-- 左側 -->
                <div class="card">
                    <mat-form-field appearance="fill">
                        <mat-label>法人・個人</mat-label>
                        <mat-select formControlName="is_company" tabindex="2">
                            <mat-option *ngFor="let result of is_company" [value]="result.value">
                                {{ result.viewValue }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>会社名／個人名</mat-label>
                        <input matInput type="text" formControlName="name" tabindex="3" />
                        <mat-error *ngIf="form.get('name').invalid">会社名は2文字以上必要です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>会社名／個人名（かな）</mat-label>
                        <input matInput type="text" formControlName="name_kana" tabindex="4" />
                        <mat-error *ngIf="form.get('name_kana').invalid">会社名（かな）は2文字以上必要です</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>支店／拠点名</mat-label>
                        <input matInput type="text" formControlName="branch_name" tabindex="5" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>郵便番号</mat-label>
                        <input matInput type="text" formControlName="zip" tabindex="6" ybPostalCode />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>都道府県</mat-label>
                        <input matInput type="text" formControlName="prefecture" tabindex="7" ybRegion />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>住所</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="address"
                            tabindex="8"
                            ybLocality
                            ybStreet
                            ybExtended
                        />
                    </mat-form-field>
                    <!-- <mat-form-field appearance="fill">
                        <mat-label>郵便番号</mat-label>
                        <input matInput type="text" formControlName="zip" tabindex="6" ybPostalCode />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>都道府県</mat-label>
                        <input matInput type="text" formControlName="prefecture" tabindex="7" ybCompletion ybRegion />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>住所</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="address"
                            tabindex="8"
                            ybLocality
                            ybStreet
                            ybExtended
                        />
                    </mat-form-field> -->
                    <mat-form-field appearance="fill">
                        <mat-label>建物名</mat-label>
                        <input matInput type="text" formControlName="building" tabindex="9" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>部屋番号</mat-label>
                        <input matInput type="text" formControlName="room_no" tabindex="10" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>電話番号</mat-label>
                        <input matInput type="text" formControlName="tel" tabindex="11" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>FAX</mat-label>
                        <input matInput type="text" formControlName="fax" tabindex="12" />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>締日</mat-label>
                        <mat-select formControlName="closing_day" tabindex="13">
                            <mat-option *ngFor="let closing_day of closing_days" [value]="closing_day.value">
                                {{ closing_day.viewValue }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>請求書送付方法</mat-label>
                        <mat-select formControlName="bill" tabindex="13">
                            <mat-option *ngFor="let bill of bills" [value]="bill.value">
                                {{ bill.viewValue }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>登録日</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="registered_at" tabindex="14" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="fill" class="note">
                    <mat-label>備考</mat-label>
                    <textarea matInput formControlName="note" tabindex="16"></textarea>
                </mat-form-field>
                <div class="submit-flex">
                    <div class="submit-flex__inner">
                        <button mat-raised-button class="submit-button" color="accent" type="submit">
                            <div *ngIf="mode == 'create'; else edit">登録する</div>
                            <ng-template #edit>
                                <div class="deleted_bg">更新する</div>
                            </ng-template>
                        </button>
                        <a mat-raised-button class="submit-button" color="basic" (click)="goBack()">戻る</a>
                    </div>
                    <p class="revive-button" *ngIf="unregistered" (click)="onRestore()">復活させる</p>
                </div>
            </form>
        </mat-card>
    </div>
</section>
