import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Item } from '../../item/item.model';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

const ITEM_BACKEND_URL = environment.apiUrl + '/item/';

interface CatNames {
    category_name1: string;
    category_name2: string;
    category_name3: string;
    category_name4: string;
};

@Injectable({
    providedIn: 'root'
})
export class ItemService {
    private items: Item[] = [];
    private itemsUpdated = new Subject<Item[]>();
    catNames: CatNames;

    constructor(private http: HttpClient, private router: Router) { }

    getItems(categoryCd: number) {
        this.http.get<{ message: string; items: Item[] }>(ITEM_BACKEND_URL + 'category/' + categoryCd).subscribe((itemData) => {
            this.items = itemData.items;
            this.itemsUpdated.next([...this.items]);
        });
    }

    getItemsUpdateListener() {
        return this.itemsUpdated.asObservable();
    }

    getItemsWithDeleted(categoryCd: number) {
        this.http.get<{ message: string; items: Item[] }>(ITEM_BACKEND_URL + 'deleted/' + categoryCd).subscribe((itemData) => {
            this.items = itemData.items;
            this.itemsUpdated.next([...this.items]);
        });
    }

    getItem(cd: string) {
        return this.http.get<{
            id: number;
            cd: string;
            name: string;
            stock: number;
            size: string;
            rental_price: number;
            count:number;
            total_sales:number;
            on_rent:number;
            shelf_no: string;
            category_minor_cd1: number;
            category_minor_cd2: number;
            category_minor_cd3: number;
            category_minor_cd4: number;
            tb_related_item_id: number;
            open_flg: string;
            keyword: string;
            purchased_shop: string;
            purchased_price :number;
            purchased_quantity :number;
            purchased_list_price :number;
            date_of_purchase :string;
            note_web: string;
            note: string;
            created_at: string;
            updated_at: string;
            deleted_at: string;
        }>(ITEM_BACKEND_URL + cd);
    }

    getCatName(catMinorCd: number) {
        return this.http.get<{majorName:string, middleName:string, minorName:string}>(ITEM_BACKEND_URL + 'category-name/' + catMinorCd);
    }

    addItemPost(
        name: string,
        stock: number,
        size: string,
        rental_price: number,
        shelf_no: string,
        open_flg: string,
        keyword: string,
        purchased_shop: string,
        purchased_list_price: number,
        purchased_price: number,
        purchased_quantity: number,
        date_of_purchase: string,
        note_web: string,
        note: string,
        category_minor_cd1: number,
        category_minor_cd2: number,
        category_minor_cd3: number,
        category_minor_cd4: number,
    ) {
        const post = {
            name,
            stock,
            size,
            rental_price,
            shelf_no,
            open_flg,
            keyword,
            purchased_shop,
            purchased_list_price,
            purchased_price,
            purchased_quantity,
            date_of_purchase,
            note_web,
            note,
            category_minor_cd1,
            category_minor_cd2,
            category_minor_cd3,
            category_minor_cd4
        };

        this.http.post<{ post: Item; message: string }>(ITEM_BACKEND_URL, post).subscribe((responseData) => {
            this.items.push(post);
            this.itemsUpdated.next([...this.items]);
            // this.router.navigate(['company-list']);
        });
    }

    deleteItem(postId: number) {
        this.http.delete(ITEM_BACKEND_URL + postId).subscribe((result) => {
            const updatedPosts = this.items.filter((item) => item.id !== postId);
            this.items = updatedPosts;
            this.itemsUpdated.next([...this.items]);
        });
    }

    getItemPost(cd: string) {
        return this.http.get<{
            cd: string;
            name: string;
            stock: number;
            size: string;
            rental_price: number;
            count: number;
            total_sales: number;
            on_rent: number;
            shelf_no: string;
            open_flg: string;
            keyword: string;
            purchased_shop: string;
            purchased_list_price: number;
            purchased_price: number;
            purchased_quantity: number;
            date_of_purchase: string;
            note_web: string;
            note: string;
            category_minor_cd1: number;
            category_minor_cd2: number;
            category_minor_cd3: number;
            category_minor_cd4: number;
            deleted_at: string;
        }>(ITEM_BACKEND_URL + cd);
    }

    updateItemPost(
        cd: string,
        name: string,
        stock: number,
        size: string,
        rental_price: number,
        shelf_no: string,
        open_flg: string,
        keyword: string,
        purchased_shop: string,
        purchased_price: number,
        purchased_quantity: number,
        purchased_list_price: number,
        date_of_purchase: string,
        note_web: string,
        note: string,
        category_minor_cd1: number,
        category_minor_cd2: number,
        category_minor_cd3: number,
        category_minor_cd4: number,
    ) {
        const post: Item = {
            cd,
            name,
            stock,
            size,
            rental_price,
            shelf_no,
            open_flg,
            keyword,
            purchased_shop,
            purchased_price,
            purchased_quantity,
            purchased_list_price,
            date_of_purchase,
            note_web,
            note,
            category_minor_cd1,
            category_minor_cd2,
            category_minor_cd3,
            category_minor_cd4,
        };
        this.http.put(ITEM_BACKEND_URL + cd, post).subscribe(response => {
            this.router.navigate(['item-list']);
        });
    }

 
    // 削除済顧客を復活
    restoreItem(cd) {
        this.http
            .delete<{ message: string }>(ITEM_BACKEND_URL + 'restore/' + cd)
            .subscribe((responseData) => {
                this.router.navigate(['item-list']);
            });
    }
}
