import { ItemService } from 'src/app/core/services/item.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Item } from '../item.model'
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

const ITEMPIC_BACKEND_URL = environment.picUrl;

// interface CategoryMinorCd {
//     category_minor_cd1: number;
//     category_minor_cd2: number;
//     category_minor_cd3: number;
//     category_minor_cd4: number;
// };

@Component({
    selector: 'app-item-detail',
    templateUrl: './item-detail.component.html',
    styleUrls: ['./item-detail.component.scss']
})


export class ItemDetailComponent implements OnInit, OnDestroy {

    constructor(
        private itemService: ItemService,
        public route: ActivatedRoute,
        private authService: AuthService,
        private location: Location
    ) { }


    item:Item;
    imgs = [];
    private cd: string;
    isLoading = false;
    // category_minor_cd: CategoryMinorCd;
    category1: string;
    category2: string;
    category3: string;
    category4: string;
    categoryName1: string;
    categoryName2: string;
    categoryName3: string;
    categoryName4: string;

    // 登録状況のバッジ表示制御
    unregistered = false;
    registerdState;

    private authStatusSub: Subscription;

    ngOnInit(): void {
        this.authStatusSub = this.authService.getAuthStatusListener().subscribe((authStatus) => {
            this.isLoading = false;
        });

        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            this.cd = paramMap.get('cd');
            this.isLoading = true;
            this.itemService.getItem(this.cd).subscribe(data => {
                this.isLoading = false;
                this.cd = data.cd;
                if (data.deleted_at) {
                    this.unregistered = true;
                }

                this.item = {
                    id: data.id,
                    cd: data.cd,
                    name: data.name,
                    stock: data.stock,
                    size: data.size,
                    rental_price: data.rental_price,
                    count:data.count,
                    total_sales:data.total_sales,
                    on_rent:data.on_rent,
                    shelf_no: data.shelf_no,
                    category_minor_cd1: data.category_minor_cd1,
                    category_minor_cd2: data.category_minor_cd2,
                    category_minor_cd3: data.category_minor_cd3,
                    category_minor_cd4: data.category_minor_cd4,
                    tb_related_item_id: data.tb_related_item_id,
                    open_flg: data.open_flg,
                    keyword: data.keyword,
                    purchased_shop: data.purchased_shop,
                    purchased_price :data.purchased_price,
                    purchased_quantity :data.purchased_quantity,
                    purchased_list_price :data.purchased_list_price,
                    date_of_purchase :data.date_of_purchase,
                    note_web: data.note_web,
                    note: data.note,
                    created_at: data.created_at,
                    updated_at: data.updated_at,
                    deleted_at: data.deleted_at,
                }

                this.setImg(data.cd);

                if (this.unregistered) {
                    this.registerdState = '削除済';
                } else {
                    this.registerdState = '登録済';
                }

                // カテゴリー名1
                this.itemService.getCatName(data.category_minor_cd1).subscribe(data => {
                    this.categoryName1 = `${data.majorName}　>　${data.middleName}　>　${data.minorName}`
                });

                // カテゴリー名2
                if (data.category_minor_cd2) {
                    this.itemService.getCatName(data.category_minor_cd2).subscribe(data => {
                        this.categoryName2 = `${data.majorName}　>　${data.middleName}　>　${data.minorName}`
                    });
                }

                // カテゴリー名3
                if (data.category_minor_cd3) {
                    this.itemService.getCatName(data.category_minor_cd3).subscribe(data => {
                        this.categoryName3 = `${data.majorName}　>　${data.middleName}　>　${data.minorName}`
                    });
                }

                // カテゴリー名4
                if (data.category_minor_cd4) {
                    this.itemService.getCatName(data.category_minor_cd4).subscribe(data => {
                        this.categoryName4 = `${data.majorName}　>　${data.middleName}　>　${data.minorName}`
                    });
                }

                // // 関連ID
                // if (data.tb_related_item_id) {
                //     this.itemService.getCatName(data.category_minor_cd4).subscribe(data => {
                //         this.categoryName4 = `${data.majorName}　>　${data.middleName}　>　${data.minorName}`
                //     });
                // }
            });
        })
    }

    setImg(cd) {
        const cd5 = ('00000' + cd).slice(-5);
        for(let i = 1; i < 7; i++) {
            this.imgs.push(ITEMPIC_BACKEND_URL + '/' + cd5 + '/' + cd5 + '-' + i);
        }
    }

    goBack(event: MouseEvent): void {
        event.preventDefault();
        this.location.back();
    }

    ngOnDestroy() {
        this.authStatusSub.unsubscribe();
    }
}
