import { AuthService } from '../../core/services/auth.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RelatedItem } from '../related-item.model';
import { RelatedItemService } from '../../core/services/related-item.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from '../../../environments/environment';

const ITEMPIC_BACKEND_URL = environment.picUrl;

@Component({
  selector: 'app-related-item-list',
  templateUrl: './related-item-list.component.html',
  styleUrls: ['./related-item-list.component.scss']
})
export class RelatedItemListComponent implements OnInit, OnDestroy {
    relatedItems: RelatedItem[] = [];
    relatedItemCount;
    current_count;
    isLoading = false;
    userRole;
    viewer = false;
    displayedColumns: string[];
    dataSource;
    checked = false;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    private relatedItemsSub: Subscription;

    constructor(
        public relatedItemService: RelatedItemService,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        this.onGetRelatedItems();
    }

    // 関連商品検索
    onGetRelatedItems() {
        this.isLoading = true;
        this.userRole = this.authService.getRoleData();
        if (this.userRole === 'viewer') {
            this.viewer = true;
        }
        
        this.relatedItemService.getRelatedItems();

        this.relatedItemsSub = this.relatedItemService.getRelatedItemsUpdateListener()
        .subscribe((relatedItems: RelatedItem[]) => {
            this.relatedItems = relatedItems;

            this.relatedItemCount = Object.keys(relatedItems).length;
            this.current_count = Object.keys(relatedItems).length;

            this.dataSource = new MatTableDataSource(relatedItems);
            this.dataSource.paginator = this.paginator;

            this.isLoading = false;

            // 画像解像度と権限によって表示カラムを変更
            // 権限がadmin, editorの場合
            if (this.userRole !== 'viewer') {
                this.displayedColumns = [
                    'id',
                    'pic',
                    'icon'
                ];

            // 権限がviewerの場合
            } else if (this.userRole === 'viewer') {
                this.displayedColumns = [
                    'id',
                    'pic'
                ];
            }
        });
    }

    getItemPic(element, num) {
        // 動的に変数を作る
        const number = num;
        const cd = "item_cd";
        const item_cd = cd + number;

        // 5桁の文字列に変更
        const cd5 = ('00000' + element[item_cd]).slice(-5);
        const path = ITEMPIC_BACKEND_URL + '/' + cd5 + '/' + cd5 + '-1-250.jpg';
        return path;
    }

    getItemLink(element, num) {
        // 動的に変数を作る
        const number = num;
        const cd = "item_cd";
        const item_cd = cd + number;

        // 5桁の文字列に変更
        const cd5 = ('00000' + element[item_cd]).slice(-5);
    }
    
    onRelatedItemDelete(id: number) {
        this.isLoading = true;
        this.relatedItemService.deleteRelatedItem(id);
    }

    // 絞り込み
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        // 絞り込み結果件数
        this.current_count = Object.keys(this.dataSource.filteredData).length;
    }

    // 削除済も表示押下時
    onAddFilter(e) {
        this.isLoading = true;
        if (!e.source.checked) {
            this.onGetRelatedItems();
        } else {
            this.relatedItemService.getRelatedItemsWithDeleted();
            this.relatedItemsSub = this.relatedItemService.getRelatedItemsUpdateListener().subscribe((relatedItems: RelatedItem[]) => {
                this.isLoading = false;
                this.relatedItems = relatedItems;
                this.relatedItemCount = Object.keys(relatedItems).length;
                this.current_count = Object.keys(relatedItems).length;

                // 全件
                this.dataSource = new MatTableDataSource(relatedItems);
                this.dataSource.paginator = this.paginator;
                this.isLoading = false;
            });
        }
    }
        
    ngOnDestroy() {
        this.relatedItemsSub.unsubscribe();
    }

}
