<section class="body">
    <ng-container>
        <!-- スマホ用ヘッダー -->
        <div class="heading">
            <h1 class="section-title">商品一覧</h1>
            <a routerLink="/item-create" class="heading__add"><img src="assets/img/common/add_icon2.svg" /></a>
            <div class="heading__search-block">
                <!-- カテゴリーで絞り込み -->
                <app-category (categoryCd)="getCategory($event)"></app-category>
                <mat-form-field>
                    <mat-label>絞り込み</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="例）バカラ" />
                </mat-form-field>
                <div class="heading__item_count">
                    {{ item_count }}/<span>{{ current_count }}件</span>
                </div>
            </div>
            <mat-checkbox
                labelPosition="before"
                class="heading__search-option"
                [(ngModel)]="checked"
                (change)="onAddFilter($event)"
                color="primary"
                >削除済も表示</mat-checkbox
            >
        </div>

        <!-- PC用ヘッダー -->
        <div class="heading-pc">
            <h1 class="section-title">商品一覧</h1>
            <div class="heading-pc__search-block">
                <!-- カテゴリーで絞り込み -->
                <app-category (categoryCd)="getCategory($event)"></app-category>
                <mat-form-field>
                    <mat-label>絞り込み</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="例）バカラ" />
                </mat-form-field>
                <mat-checkbox
                    labelPosition="before"
                    class="heading-pc__search-option"
                    [(ngModel)]="checked"
                    (change)="onAddFilter($event)"
                    color="primary"
                    >削除済も表示</mat-checkbox
                >
                <div class="heading-pc__item_count">
                    {{ item_count }}/<span>{{ current_count }}件</span>
                </div>
            </div>
            <a routerLink="/item-create" class="heading-pc__btn secondary-button" *ngIf="!viewer">商品登録</a>
        </div>

        <p class="breadcrumb">{{ breadCrumb }}</p>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <div class="mat-elevation-z8">
            <mat-table *ngIf="!isLoading" [dataSource]="dataSource">
                <ng-container matColumnDef="cd">
                    <mat-header-cell *matHeaderCellDef>商品CD</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div *ngIf="element.deleted_at === null; else deleted_block">{{ element.cd }}</div>
                        <ng-template #deleted_block>
                            <div class="deleted_bg">{{ element.cd }}</div>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="pic">
                    <mat-header-cell *matHeaderCellDef>画像</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <ng-container *ngIf="getItemPic(element); else picElseBlock">
                            <img [src]="getItemPic(element)" class="pic__img" />
                        </ng-container>
                        <ng-template #picElseBlock>
                            <img src="/backend/images/items/no-image.svg" class="pic__img" />
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>商品名</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <a [routerLink]="['/item-detail', element.cd]" class="detail-link">
                            {{ element.name }}
                        </a>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="rental_price">
                    <mat-header-cell *matHeaderCellDef>レンタル料金（税抜）</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.rental_price | number: '0.0-0' }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="stock">
                    <mat-header-cell *matHeaderCellDef>在庫数</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.stock }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="count">
                    <mat-header-cell *matHeaderCellDef>出荷累計</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.count }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="open_flg">
                    <mat-header-cell *matHeaderCellDef>状態</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.open_flg == '公開'; else openElseBlock"> 公開 </ng-container>
                        <ng-template #openElseBlock> 非公開 </ng-template>
                    </mat-cell>
                </ng-container>

                <!-- アイコン -->
                <ng-container *ngIf="!viewer; else not_login">
                    <ng-container matColumnDef="icon">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element" class="icon">
                            <ul class="icon__list">
                                <li class="icon__item">
                                    <a [routerLink]="['/item-edit', element.cd]" title="編集"
                                        ><i class="icon__edit"></i
                                    ></a>
                                </li>

                                <li *ngIf="element.deleted_at === null" class="icon__item">
                                    <a (click)="onItemDelete(element.id)" title="削除"><i class="icon__delete"></i></a>
                                </li>
                                <li *ngIf="element.deleted_at !== null" class="icon__item">
                                    <i class="icon__delete" style="cursor: default; pointer-events: none"></i>
                                </li>
                            </ul>
                        </mat-cell>
                    </ng-container>
                </ng-container>
                <ng-template #not_login>
                    <ng-container matColumnDef="icon">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element" class="icon"></mat-cell>
                    </ng-container>
                </ng-template>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [ngClass]="{ deleted_bg: row.deleted_at !== null }"
                ></mat-row>
            </mat-table>
        </div>

        <p class="info-text mat-body-1" *ngIf="items.length <= 0">登録件数はゼロです。</p>
        <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
    </ng-container>
</section>
