import { AuthService } from '../../core/services/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Company } from '../company.model';
import { CmsService } from '../../core/services/cms.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-company-search',
    templateUrl: './company-search.component.html',
    styleUrls: ['./company-search.component.scss'],
})
export class CompanySearchComponent implements OnInit {
    posts: Company[] = [];
    post_count;
    current_count;
    private postsSub: Subscription;
    isLoading = false;
    userRole;
    viewer = false;

    dataSource;
    checked = false;

    constructor(public cmsService: CmsService, private authService: AuthService) {}

    ngOnInit() {
        this.isLoading = true;
        this.cmsService.getCompanyPosts();

        this.userRole = this.authService.getRoleData();
        if (this.userRole === 'viewer') {
            this.viewer = true;
        }

        this.postsSub = this.cmsService.getCompanyPostsUpdateListener().subscribe((posts: Company[]) => {
            this.posts = posts;
            this.post_count = Object.keys(posts).length;
            this.current_count = Object.keys(posts).length;

            this.dataSource = new MatTableDataSource(posts);
            this.isLoading = false;
        });
    }

    displayedColumns: string[] = ['cd', 'name', 'branch_name', 'prefecture'];

    // 絞り込み
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        // 絞り込み結果件数
        this.current_count = Object.keys(this.dataSource.filteredData).length;
    }

    // 「削除済も表示」押下時
    onAddFilter(e) {
        this.isLoading = true;
        if (!e.source.checked) {
            this.cmsService.getCompanyPosts();
            this.postsSub = this.cmsService.getCompanyPostsUpdateListener().subscribe((posts: Company[]) => {
                this.isLoading = false;
                this.posts = posts;
                this.post_count = Object.keys(posts).length;
                this.current_count = Object.keys(posts).length;

                // 全件
                this.dataSource = new MatTableDataSource(posts);
            });
        } else {
            this.cmsService.getCompanyPostsWithDeleted();
            this.postsSub = this.cmsService.getCompanyPostsUpdateListener().subscribe((posts: Company[]) => {
                this.isLoading = false;
                this.posts = posts;
                this.post_count = Object.keys(posts).length;
                this.current_count = Object.keys(posts).length;

                // 全件
                this.dataSource = new MatTableDataSource(posts);
            });
        }
    }

    ngOnDestroy() {
        this.postsSub.unsubscribe();
    }
}
