import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../core/services/role-guard.service';
import { AuthGuard } from '../auth/auth.guard';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderCreateComponent } from './order-create/order-create.component';

const routes: Routes = [
    { path: 'order-list', component: OrderListComponent, canActivate: [AuthGuard]},
    { path: 'order-create', component: OrderCreateComponent, canActivate: [RoleGuard], data: { role: ['admin', 'editor'] } },
    // { path: 'item-edit/:postCd', component: ItemCreateComponent, canActivate: [RoleGuard], data: { role: ['admin', 'editor'] } },
    // { path: 'item-detail/:cd', component: ItemDetailComponent, canActivate: [AuthGuard] },
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CommonModule, RouterModule.forChild(routes)
  ]
})
export class OrderRoutingModule { }
