import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Company } from '../../cms/company.model';
import { Customer } from '../../cms/customer.model';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

const COMPANY_BACKEND_URL = environment.apiUrl + '/company-posts/';
const CUSTOMER_BACKEND_URL = environment.apiUrl + '/customer-posts/';

@Injectable({
    providedIn: 'root',
})
export class CmsService {
    // COMPANY
    private companyPosts: Company[] = [];
    private companyPostsUpdated = new Subject<Company[]>();

    constructor(private http: HttpClient, private router: Router) {}

    getCompanyPosts() {
        this.http.get<{ message: string; posts: Company[] }>(COMPANY_BACKEND_URL).subscribe((postData) => {
            this.companyPosts = postData.posts;
            this.companyPostsUpdated.next([...this.companyPosts]);
        });
    }

    getCompanyPostsOrderKana() {
        this.http.get<{ message: string; posts: Company[] }>(COMPANY_BACKEND_URL + 'kana').subscribe((postData) => {
            this.companyPosts = postData.posts;
            this.companyPostsUpdated.next([...this.companyPosts]);
        });
    }

    getCompanyPostsUpdateListener() {
        return this.companyPostsUpdated.asObservable();
    }

    getCompanyPostsWithDeleted() {
        this.http.get<{ message: string; posts: Company[] }>(COMPANY_BACKEND_URL + 'deleted').subscribe((postData) => {
            this.companyPosts = postData.posts;
            this.companyPostsUpdated.next([...this.companyPosts]);
        });
    }

    getCompanyPost(cd: string) {
        return this.http.get<{
            // id: string;
            cd: string;
            name: string;
            name_kana: string;
            branch_name: string;
            zip: string;
            prefecture: string;
            address: string;
            building: string;
            room_no: string;
            tel: string;
            fax: string;
            closing_day: string;
            bill: string;
            is_company: string;
            registered_at: string;
            updated_at: string;
            creator: string;
            note: string;
            deleted_at: string;
        }>(COMPANY_BACKEND_URL + cd);
    }

    addCompanyPost(
        name: string,
        name_kana: string,
        branch_name: string,
        zip: string,
        prefecture: string,
        address: string,
        building: string,
        room_no: string,
        tel: string,
        fax: string,
        closing_day: string,
        bill: string,
        is_company: string,
        registered_at: string,
        creator: string,
        note: string
    ) {
        const post: Company = {
            name,
            name_kana,
            branch_name,
            zip,
            prefecture,
            address,
            building,
            room_no,
            tel,
            fax,
            closing_day,
            bill,
            is_company,
            registered_at,
            creator,
            note,
        };

        this.http.post<{ message: string }>(COMPANY_BACKEND_URL, post).subscribe((responseData) => {
            this.companyPosts.push(post);
            this.companyPostsUpdated.next([...this.companyPosts]);
            this.router.navigate(['company-list']);
        });
    }

    updateCompanyPost(
        cd: string,
        name: string,
        name_kana: string,
        branch_name: string,
        zip: string,
        prefecture: string,
        address: string,
        building: string,
        room_no: string,
        tel: string,
        fax: string,
        closing_day: string,
        bill: string,
        is_company: string,
        registered_at: string,
        note: string
    ) {
        const post: Company = {
            cd,
            name,
            name_kana,
            branch_name,
            zip,
            prefecture,
            address,
            building,
            room_no,
            tel,
            fax,
            closing_day,
            bill,
            is_company,
            registered_at,
            note,
        };
        this.http.put(COMPANY_BACKEND_URL + cd, post).subscribe((response) => {
            this.router.navigate(['company-list']);
        });
    }

    deleteCompanyPost(postCd: string) {
        this.http.delete(COMPANY_BACKEND_URL + postCd).subscribe((result) => {
            const updatedPosts = this.companyPosts.filter((post) => post.cd !== postCd);
            this.companyPosts = updatedPosts;
            this.companyPostsUpdated.next([...this.companyPosts]);
            // this.router.navigate(['company-list']);
        });
    }

    // 削除済を復活
    restoreCompanyPost(postCd) {
        this.http.delete<{ message: string }>(COMPANY_BACKEND_URL + 'restore/' + postCd).subscribe((responseData) => {
            this.router.navigate(['company-list']);
        });
    }

    // CUSTOMER
    private customerPosts: Customer[] = [];
    private customerPostsUpdated = new Subject<Customer[]>();

    getCustomerPosts() {
        this.http.get<{ message: string; posts: Customer[] }>(CUSTOMER_BACKEND_URL).subscribe((postData) => {
            // console.log(postData);
            this.customerPosts = postData.posts;
            this.customerPostsUpdated.next([...this.customerPosts]);
        });
    }

    getCustomerPostsUpdateListener() {
        return this.customerPostsUpdated.asObservable();
    }

    getCustomerPostsWithDeleted() {
        this.http
            .get<{ message: string; posts: Customer[] }>(CUSTOMER_BACKEND_URL + 'deleted')
            .subscribe((postData) => {
                this.customerPosts = postData.posts;
                this.customerPostsUpdated.next([...this.customerPosts]);
                // console.log(postData.posts);
            });
    }

    getCustomerPost(id: string) {
        return this.http.get<{
            id: string;
            cd: string;
            name: string;
            name_kana: string;
            department: string;
            position: string;
            email: string;
            mobile_phone: string;
            image_path: string;
            file_name: string;
            registered_at: string;
            updated_at: string;
            creator: string;
            note: string;
            deleted_at: string;
            tb_company_cd: string;
        }>(CUSTOMER_BACKEND_URL + id);
    }

    addCustomerPost(
        name: string,
        name_kana: string,
        tb_company_cd: string,
        department: string,
        position: string,
        email: string,
        mobile_phone: string,
        image: File,
        registered_at: string,
        // creator: string,
        note: string
    ) {
        const postData = new FormData();
        postData.append('name', name);
        postData.append('name_kana', name_kana);
        postData.append('tb_company_cd', tb_company_cd);
        postData.append('department', department);
        postData.append('position', position);
        postData.append('email', email);
        postData.append('mobile_phone', mobile_phone);
        if (image) {
            postData.append('image', image);
        }
        postData.append('registered_at', registered_at);
        // postData.append('creator', creator);
        postData.append('note', note);

        this.http
            .post<{ message: string; post: Customer }>(CUSTOMER_BACKEND_URL, postData)
            .subscribe((responseData) => {
                const customerPost: Customer = {
                    id: responseData.post.id,
                    cd: responseData.post.cd,
                    name,
                    name_kana,
                    tb_company_cd,
                    department,
                    position,
                    email,
                    mobile_phone,
                    image_path: responseData.post.image_path,
                    file_name: responseData.post.file_name,
                    registered_at,
                    creator: responseData.post.creator,
                    note,
                };
                this.customerPosts.push(customerPost);
                this.customerPostsUpdated.next([...this.customerPosts]);
                this.router.navigate(['customer-list']);
            });
    }

    updateCustomerPost(
        id: string,
        cd: string,
        name: string,
        name_kana: string,
        tb_company_cd: string,
        department: string,
        position: string,
        email: string,
        mobile_phone: string,
        image: File | string,
        file_name: string,
        registered_at: string,
        note: string
    ) {
        let postData: Customer | FormData;

        // imageがobjectならばformDataを作る。文字列ならば普通のjsonデータを作る。
        if (typeof image === 'object') {
            postData = new FormData();
            postData.append('id', id);
            postData.append('cd', cd);
            postData.append('name', name);
            postData.append('name_kana', name_kana);
            postData.append('tb_company_cd', tb_company_cd);
            postData.append('department', department);
            postData.append('position', position);
            postData.append('email', email);
            postData.append('mobile_phone', mobile_phone);
            postData.append('image', image);
            postData.append('registered_at', registered_at);
            postData.append('note', note);
        } else {
            postData = {
                id,
                cd,
                name,
                name_kana,
                tb_company_cd,
                department,
                position,
                email,
                mobile_phone,
                image_path: image,
                file_name: file_name,
                registered_at,
                note,
            };
        }

        this.http.put(CUSTOMER_BACKEND_URL + id, postData).subscribe((response) => {
            const updatedPosts = [...this.customerPosts];
            const oldPostIndex = updatedPosts.findIndex((p) => p.id === id);
            const post: Customer = {
                id,
                cd,
                name,
                name_kana,
                tb_company_cd,
                department,
                position,
                email,
                mobile_phone,
                image_path: '',
                file_name,
                registered_at,
                note,
            };
            updatedPosts[oldPostIndex] = post;
            this.customerPosts = updatedPosts;
            this.customerPostsUpdated.next([...this.customerPosts]);
            this.router.navigate(['customer-list']);
        });
    }

    // 会社CDに紐づく顧客CD
    getCompanyCustomerPostCd(companyCd: string) {
        return this.http.get<{
            cd: string;
        }>(CUSTOMER_BACKEND_URL + 'company-customer-cd/' + companyCd);
    }

    // 会社CDに紐づく顧客データ
    getCompanyCustomer(companyCd: string) {
        return this.http.get<{ post: Customer }>(CUSTOMER_BACKEND_URL + 'company-customer/' + companyCd);
    }

    deleteCustomerPost(postId: string) {
        this.http.delete(CUSTOMER_BACKEND_URL + postId).subscribe((result) => {
            const updatedPosts = this.customerPosts.filter((post) => post.id !== postId);
            this.customerPosts = updatedPosts;
            this.customerPostsUpdated.next([...this.customerPosts]);
        });
    }

    // 会社IDに紐づく顧客を全て削除
    deleteCustomerPosts(postCds: any[]) {
        for (let i = 0; i < postCds.length; i++) {
            this.http.delete(CUSTOMER_BACKEND_URL + 'company-customer/' + postCds[i].cd).subscribe((result) => {});
        }
    }

    // 削除済顧客を復活
    restoreCustomerPost(customerId) {
        this.http
            .delete<{ message: string }>(CUSTOMER_BACKEND_URL + 'restore/' + customerId)
            .subscribe((responseData) => {
                this.router.navigate(['company-list']);
            });
    }

    // 会社検索
    getSelectedCompany(cd: string) {
        return this.http.get<{
            cd: string;
            name: string;
            name_kana: string;
            branch_name: string;
            zip: string;
            prefecture: string;
            address: string;
            building: string;
            room_no: string;
            tel: string;
            fax: string;
            closing_day: string;
            bill: string;
            is_company: string;
            registered_at: string;
            updated_at: string;
            creator: string;
            note: string;
            deleted_at?: string;
        }>(CUSTOMER_BACKEND_URL + 'company-search/' + cd);
    }

    // 最新顧客CD取得
    getNewCustomerCD() {
        return this.http.get<{ cd: string }>(CUSTOMER_BACKEND_URL + 'customer-cd');
    }
}
