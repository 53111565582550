import { CmsService } from '../../core/services/cms.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Customer } from '../customer.model';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-customer-detail',
    templateUrl: './customer-detail.component.html',
    styleUrls: ['./customer-detail.component.scss'],
})
export class CustomerDetailComponent implements OnInit, OnDestroy {
    constructor(
        public cmsService: CmsService,
        public route: ActivatedRoute,
        private authService: AuthService,
        private location: Location
    ) {}

    private postId: string;
    post: Customer;
    tb_company_cd;
    tb_company_name: string;
    tb_company_name_kana: string;
    tb_company_branch_name: string;
    tb_company_zip: string;
    tb_company_prefecture: string;
    tb_company_address: string;
    tb_company_building: string;
    tb_company_room_no: string;
    tb_company_tel: string;
    tb_company_fax: string;
    tb_company_closing_day: string;
    tb_company_bill: string;
    tb_company_is_company: string;
    tb_company_registered_at: string;
    tb_company_updated_at: string;
    tb_company_creator: string;
    tb_company_note: string;
    tb_company_deleted_at?: string;

    isLoading = false;

    // 登録状況のバッジ表示制御
    unregistered = false;
    registerdState;

    private authStatusSub: Subscription;

    ngOnInit(): void {
        this.authStatusSub = this.authService.getAuthStatusListener().subscribe((authStatus) => {
            this.isLoading = false;
        });

        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            this.postId = paramMap.get('postId');
            this.isLoading = true;
            this.cmsService.getCustomerPost(this.postId).subscribe((postData) => {
                this.isLoading = false;
                this.tb_company_cd = postData.tb_company_cd;
                if (postData.deleted_at) {
                    this.unregistered = true;
                }

                this.post = {
                    id: postData.id,
                    cd: postData.cd,
                    tb_company_cd: postData.tb_company_cd,
                    name: postData.name,
                    name_kana: postData.name_kana,
                    department: postData.department,
                    position: postData.position,
                    email: postData.email,
                    mobile_phone: postData.mobile_phone,
                    image_path: postData.image_path,
                    file_name: postData.file_name,
                    registered_at: postData.registered_at,
                    updated_at: postData.updated_at,
                    note: postData.note,
                    deleted_at: postData.deleted_at,
                };

                this.cmsService.getSelectedCompany(this.tb_company_cd).subscribe((companyData) => {
                    this.tb_company_cd = companyData.cd;
                    this.tb_company_name = companyData.name;
                    this.tb_company_name_kana = companyData.name_kana;
                    this.tb_company_branch_name = companyData.branch_name;
                    this.tb_company_zip = companyData.zip;
                    this.tb_company_prefecture = companyData.prefecture;
                    this.tb_company_address = companyData.address;
                    this.tb_company_building = companyData.building;
                    this.tb_company_room_no = companyData.room_no;
                    this.tb_company_tel = companyData.tel;
                    this.tb_company_fax = companyData.fax;
                    this.tb_company_closing_day = companyData.closing_day;
                    this.tb_company_bill = companyData.bill;
                    this.tb_company_is_company = companyData.is_company;
                    this.tb_company_registered_at = companyData.registered_at;
                    this.tb_company_updated_at = companyData.updated_at;
                    this.tb_company_creator = companyData.creator;
                    this.tb_company_note = companyData.note;
                    this.tb_company_deleted_at = companyData.deleted_at;
                });

                if (this.unregistered) {
                    this.registerdState = '削除済';
                } else {
                    this.registerdState = '登録済';
                }
            });
        });
    }

    goBack(event: MouseEvent): void {
        event.preventDefault();
        this.location.back();
    }

    ngOnDestroy() {
        this.authStatusSub.unsubscribe();
    }
}
