<section class="body">
    <ng-container>
        <!-- スマホ用ヘッダー -->
        <div class="heading">
            <h1 class="section-title">関連商品一覧</h1>
            <a routerLink="/related-item-create" class="heading__add"><img src="assets/img/common/add_icon2.svg" /></a>
            <div class="heading__search-block">
                <mat-form-field>
                    <mat-label>絞り込み</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="例）12345" />
                </mat-form-field>
                <div class="heading__item_count">
                    {{ relatedItemCount }}/<span>{{ current_count }}件</span>
                </div>
            </div>
            <mat-checkbox
                labelPosition="before"
                class="heading__search-option"
                [(ngModel)]="checked"
                (change)="onAddFilter($event)"
                color="primary"
                >削除済も表示</mat-checkbox
            >
        </div>

        <!-- PC用ヘッダー -->
        <div class="heading-pc">
            <h1 class="section-title">関連商品一覧</h1>
            <div class="heading-pc__search-block">
                <mat-form-field>
                    <mat-label>絞り込み</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="例）12345" />
                </mat-form-field>
                <mat-checkbox
                    labelPosition="before"
                    class="heading-pc__search-option"
                    [(ngModel)]="checked"
                    (change)="onAddFilter($event)"
                    color="primary"
                    >削除済も表示</mat-checkbox
                >
                <div class="heading-pc__item_count">
                    {{ relatedItemCount }}/<span>{{ current_count }}件</span>
                </div>
            </div>
            <a routerLink="/related-item-create" class="heading-pc__btn secondary-button" *ngIf="!viewer"
                >関連商品登録</a
            >
        </div>

        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <div class="mat-elevation-z8">
            <mat-table *ngIf="!isLoading" [dataSource]="dataSource">
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div *ngIf="element.deleted_at === null; else deleted_block">{{ element.id }}</div>
                        <ng-template #deleted_block>
                            <div class="deleted_bg">{{ element.id }}</div>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <!-- 画像1 -->
                <ng-container matColumnDef="pic">
                    <mat-header-cell *matHeaderCellDef>画像</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <ng-container>
                            <a [routerLink]="['/item-detail', element.item_cd1]" class="detail-link">
                                <img [src]="getItemPic(element, 1)" class="pic__img" />
                            </a>
                            <ng-container *ngIf="element.item_cd2 != null && element.item_cd2 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd2]">
                                    <img [src]="getItemPic(element, 2)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd3 != null && element.item_cd3 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd3]">
                                    <img [src]="getItemPic(element, 3)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd4 != null && element.item_cd4 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd4]">
                                    <img [src]="getItemPic(element, 4)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd5 != null && element.item_cd5 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd5]">
                                    <img [src]="getItemPic(element, 5)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd6 != null && element.item_cd6 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd6]">
                                    <img [src]="getItemPic(element, 6)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd7 != null && element.item_cd7 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd7]">
                                    <img [src]="getItemPic(element, 7)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd8 != null && element.item_cd8 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd8]">
                                    <img [src]="getItemPic(element, 8)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd9 != null && element.item_cd9 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd9]">
                                    <img [src]="getItemPic(element, 9)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd10 != null && element.item_cd10 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd10]">
                                    <img [src]="getItemPic(element, 10)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd11 != null && element.item_cd11 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd11]">
                                    <img [src]="getItemPic(element, 11)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd12 != null && element.item_cd12 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd12]">
                                    <img [src]="getItemPic(element, 12)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd13 != null && element.item_cd13 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd13]">
                                    <img [src]="getItemPic(element, 13)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd14 != null && element.item_cd14 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd14]">
                                    <img [src]="getItemPic(element, 14)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd15 != null && element.item_cd15 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd15]">
                                    <img [src]="getItemPic(element, 15)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd16 != null && element.item_cd16 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd16]">
                                    <img [src]="getItemPic(element, 16)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd17 != null && element.item_cd17 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd17]">
                                    <img [src]="getItemPic(element, 17)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd18 != null && element.item_cd18 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd18]">
                                    <img [src]="getItemPic(element, 18)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd19 != null && element.item_cd19 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd19]">
                                    <img [src]="getItemPic(element, 19)" class="pic__img" />
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.item_cd20 != null && element.item_cd20 != 0">
                                <a [routerLink]="['/item-detail', element.item_cd20]">
                                    <img [src]="getItemPic(element, 20)" class="pic__img" />
                                </a>
                            </ng-container>
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <!-- アイコン -->
                <ng-container *ngIf="!viewer; else not_login">
                    <ng-container matColumnDef="icon">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element" class="icon">
                            <ul class="icon__list">
                                <li class="icon__item">
                                    <a [routerLink]="['/related-item-edit', element.id]" title="編集"
                                        ><i class="icon__edit"></i
                                    ></a>
                                </li>

                                <li *ngIf="element.deleted_at === null" class="icon__item">
                                    <a (click)="onRelatedItemDelete(element.id)" title="削除"
                                        ><i class="icon__delete"></i
                                    ></a>
                                </li>
                                <li *ngIf="element.deleted_at !== null" class="icon__item">
                                    <i class="icon__delete" style="cursor: default; pointer-events: none"></i>
                                </li>
                            </ul>
                        </mat-cell>
                    </ng-container>
                </ng-container>
                <ng-template #not_login>
                    <ng-container matColumnDef="icon">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element" class="icon"></mat-cell>
                    </ng-container>
                </ng-template>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [ngClass]="{ deleted_bg: row.deleted_at !== null }"
                ></mat-row>
            </mat-table>
        </div>

        <p class="info-text mat-body-1" *ngIf="relatedItems.length <= 0">登録件数はゼロです。</p>
        <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
    </ng-container>
</section>
