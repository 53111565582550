<section>
    <div class="body">
        <h1 class="section-title">商品情報閲覧</h1>
        <mat-spinner *ngIf="isLoading"></mat-spinner>

        <!-- スマホ用ヘッダー -->
        <div class="heading-sp">
            <mat-card class="sp-card-top">
                <div class="sp-card-top__box">
                    総売上高<span class="sp-card-top__value"
                        >{{ item?.total_sales | number: '0.0-0' }}<span class="sp-card-top__unit">円</span></span
                    >
                </div>
                <div class="sp-card-top__box">
                    出荷回数（過去1年）<span class="sp-card-top__value"
                        >4<span class="sp-card-top__unit">回</span></span
                    >
                </div>

                <div class="sp-card-top__box">
                    出荷回数（過去3年）<span class="sp-card-top__value"
                        >9<span class="sp-card-top__unit">回</span></span
                    >
                </div>
                <div class="sp-card-top__box">
                    総出荷回数<span class="sp-card-top__value"
                        >{{ item?.count }}<span class="sp-card-top__unit">回</span></span
                    >
                </div>
                <div class="sp-card-top__box">現在の在庫数<span class="sp-card-top__value">1</span></div>
            </mat-card>
        </div>

        <!-- PC用ヘッダー -->
        <div class="heading-pc">
            <mat-card class="pc-card-top">
                <div>
                    総売上高<span class="pc-card-top__value"
                        >{{ item?.total_sales | number: '0.0-0' }}<span class="pc-card-top__unit">円</span></span
                    >
                </div>
                <div>
                    出荷回数（過去1年）<span class="pc-card-top__value"
                        >4<span class="pc-card-top__unit">回</span></span
                    >
                </div>
                <div>
                    出荷回数（過去3年）<span class="pc-card-top__value"
                        >9<span class="pc-card-top__unit">回</span></span
                    >
                </div>
                <div>
                    総出荷回数<span class="pc-card-top__value"
                        >{{ item?.count }}<span class="pc-card-top__unit">回</span></span
                    >
                </div>
                <div>現在の在庫数<span class="pc-card-top__value">1</span></div>
            </mat-card>
        </div>
        <div class="mat-card-wrap">
            <!-- 左側 -->
            <mat-card class="card-half">
                <!-- <div class="card__heading">
                    <div class="registerd_state" [class.unregistered]="unregistered">
                        {{ registerdState }}
                    </div>
                </div> -->

                <table class="box__table">
                    <tr>
                        <th>商品CD</th>
                        <td>{{ item?.cd }}</td>
                    </tr>
                    <tr>
                        <th>商品名</th>
                        <td>{{ item?.name }}</td>
                    </tr>
                    <tr>
                        <th>在庫数</th>
                        <td>{{ item?.stock }}</td>
                    </tr>
                    <tr>
                        <th>寸法</th>
                        <td>{{ item?.size }}</td>
                    </tr>
                    <tr>
                        <th>レンタル料金（税抜）</th>
                        <td>{{ item?.rental_price | number: '0.0-0' }} 円</td>
                    </tr>
                    <tr>
                        <th>カテゴリー1</th>
                        <td>{{ categoryName1 }}</td>
                    </tr>
                    <tr>
                        <th>カテゴリー2</th>
                        <td>{{ categoryName2 }}</td>
                    </tr>
                    <tr>
                        <th>カテゴリー3</th>
                        <td>{{ categoryName3 }}</td>
                    </tr>
                    <tr>
                        <th>カテゴリー4</th>
                        <td>{{ categoryName4 }}</td>
                    </tr>
                    <tr>
                    <tr>
                        <th>関連ID</th>
                        <td>{{ item?.tb_related_item_id }}</td>
                    </tr>
                    <tr>
                        <th>棚番号</th>
                        <td>{{ item?.shelf_no }}</td>
                    </tr>
                    <tr>
                        <th>公開フラグ</th>
                        <td>{{ item?.open_flg }}</td>
                    </tr>
                    <tr>
                        <th>キーワード</th>
                        <td>{{ item?.keyword }}</td>
                    </tr>
                    <tr>
                        <th>購入店名</th>
                        <td>{{ item?.purchased_shop }}</td>
                    </tr>
                    <tr>
                        <th>定価（税込）</th>
                        <td>{{ item?.purchased_list_price | number: '0.0-0' }}円</td>
                    </tr>
                    <tr>
                        <th>購入金額（税込）</th>
                        <td>{{ item?.purchased_price | number: '0.0-0' }}円</td>
                    </tr>
                    <tr>
                        <th>購入個数</th>
                        <td>{{ item?.purchased_quantity }}</td>
                    </tr>
                    <tr>
                        <th>購入日</th>
                        <td>{{ item?.date_of_purchase | date: 'yyyy年MM月dd日' }}</td>
                    </tr>
                    <tr>
                        <th>削除日</th>
                        <td>{{ item?.deleted_at | date: 'yyyy年MM月dd日' }}</td>
                    </tr>
                    <tr>
                        <th>備考（フロント用）</th>
                        <td>{{ item?.note_web }}</td>
                    </tr>
                    <tr>
                        <th>備考（バック用）</th>
                        <td>{{ item?.note }}</td>
                    </tr>
                </table>
            </mat-card>

            <!-- 右側 -->
            <!-- 写真 -->
            <div class="card-half-wrap">
                <mat-card class="card-half img-box">
                    <div class="img-block">
                        <div *ngFor="let img of imgs" class="img-wrap">
                            <a href="{{ img }}-1280.jpg" class="img-link">
                                <img
                                    src="{{ img }}-250.jpg"
                                    alt
                                    onerror="this.onerror = null; this.src='';"
                                    class="img"
                                />
                            </a>
                        </div>
                    </div>
                </mat-card>

                <!-- 売上・出荷データ -->
                <mat-card class="card-half rank">
                    <div class="rank__block">
                        <h3 class="rank__title">売上順位（全体）</h3>
                        <div class="rank__data-block">
                            <div class="rank__item">過去30日<span>4,851</span></div>
                            <div class="rank__item">半年<span>4,622</span></div>
                            <div class="rank__item">1年<span>3,157</span></div>
                            <div class="rank__item">全期間<span>5,120</span></div>
                        </div>
                        <div class="rank__paramater">母数：10,195</div>
                    </div>
                    <div class="rank__block">
                        <h3 class="rank__title">出荷回数順位（全体）</h3>
                        <div class="rank__data-block">
                            <div class="rank__item">過去30日<span>3,210</span></div>
                            <div class="rank__item">半年<span>3,350</span></div>
                            <div class="rank__item">1年<span>3,561</span></div>
                            <div class="rank__item">全期間<span>3,401</span></div>
                        </div>
                        <div class="rank__paramater">母数：10,195</div>
                    </div>
                    <div class="rank__block">
                        <h3 class="rank__title">出荷回数順位（同一カテゴリ）</h3>
                        <div class="rank__data-block">
                            <div class="rank__item">過去30日<span>4</span></div>
                            <div class="rank__item">半年<span>5</span></div>
                            <div class="rank__item">1年<span>8</span></div>
                            <div class="rank__item">全期間<span>6</span></div>
                        </div>
                        <div class="rank__paramater">母数：21</div>
                    </div>
                </mat-card>

                <!-- 出荷先データ -->
                <mat-card class="card-half history">
                    <table class="history__table">
                        <thead>
                            <tr>
                                <th>出荷先</th>
                                <th>出荷日</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><a href="">株式会社フクモトスタジオ</a></td>
                                <td style="color: #ff4668">2021/07/30</td>
                            </tr>
                            <tr>
                                <td><a href="">〇〇スタイリング</a></td>
                                <td>2021/07/16</td>
                            </tr>
                            <tr>
                                <td><a href="">〇〇スタイリング</a></td>
                                <td>2021/07/16</td>
                            </tr>
                            <tr>
                                <td><a href="">〇〇スタイリング</a></td>
                                <td>2021/07/16</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card>
            </div>
        </div>
        <div class="submit-flex">
            <button mat-raised-button class="submit-button back-button" color="basic" (click)="goBack($event)">
                戻る
            </button>
        </div>
    </div>
</section>
