import { CmsService } from '../../core/services/cms.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Company } from '../company.model';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-company-detail',
    templateUrl: './company-detail.component.html',
    styleUrls: ['./company-detail.component.scss'],
})
export class CompanyDetailComponent implements OnInit, OnDestroy {
    constructor(
        public cmsService: CmsService,
        public route: ActivatedRoute,
        private authService: AuthService,
        private location: Location
    ) {}

    closing_days = [
        { value: '都度清算', viewValue: '都度清算' },
        { value: '20日', viewValue: '20日' },
        { value: '現金後日', viewValue: '現金後日' },
        { value: '末日', viewValue: '末日' },
        { value: '不明', viewValue: '不明' },
    ];

    private postCd: string;
    post: Company;
    isLoading = false;
    companyCustomers;

    // 登録状況のバッジ表示制御
    unregistered = false;
    registerdState;

    private authStatusSub: Subscription;

    ngOnInit(): void {
        this.authStatusSub = this.authService.getAuthStatusListener().subscribe((authStatus) => {
            this.isLoading = false;
        });

        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            this.postCd = paramMap.get('postCd');
            this.isLoading = true;
            this.cmsService.getCompanyPost(this.postCd).subscribe((postData) => {
                this.isLoading = false;
                if (postData.deleted_at) {
                    this.unregistered = true;
                }
                this.post = {
                    cd: postData.cd,
                    name: postData.name,
                    name_kana: postData.name_kana,
                    branch_name: postData.branch_name,
                    zip: postData.zip,
                    prefecture: postData.prefecture,
                    address: postData.address,
                    building: postData.building,
                    room_no: postData.room_no,
                    tel: postData.tel,
                    fax: postData.fax,
                    closing_day: postData.closing_day,
                    bill: postData.bill,
                    is_company: postData.is_company,
                    registered_at: postData.registered_at,
                    updated_at: postData.updated_at,
                    deleted_at: postData.deleted_at,
                    note: postData.note,
                };

                if (this.unregistered) {
                    this.registerdState = '削除済';
                } else {
                    this.registerdState = '登録済';
                }
            });
        });
        this.cmsService.getCompanyCustomer(this.postCd).subscribe((postData) => {
            this.companyCustomers = postData.post;
        });
    }

    goBack(): void {
        this.location.back();
    }

    ngOnDestroy() {
        this.authStatusSub.unsubscribe();
    }
}
