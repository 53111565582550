import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { RelatedItem } from '../../related-item/related-item.model';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

const ITEM_BACKEND_URL = environment.apiUrl + '/related-item/';

@Injectable({
    providedIn: 'root'
})
export class RelatedItemService {
    private relatedItems: RelatedItem[] = [];
    private relatedItemsUpdated = new Subject<RelatedItem[]>();

    constructor(private http: HttpClient, private router: Router) { }
  
    getRelatedItems() {
        this.http.get<{ message: string; relatedItems: RelatedItem[] }>(ITEM_BACKEND_URL).subscribe((relatedItemData) => {
            this.relatedItems = relatedItemData.relatedItems;
            this.relatedItemsUpdated.next([...this.relatedItems]);
        });
    }

    getRelatedItemsUpdateListener() {
        return this.relatedItemsUpdated.asObservable();
    }

    getRelatedItemsWithDeleted() {
        this.http.get<{ message: string; relatedItems: RelatedItem[] }>(ITEM_BACKEND_URL + 'deleted/').subscribe((itemData) => {
            this.relatedItems = itemData.relatedItems;
            this.relatedItemsUpdated.next([...this.relatedItems]);
        });
    }

    deleteRelatedItem(id: number) {
        this.http.delete(ITEM_BACKEND_URL + id).subscribe((result) => {
            const updatedPosts = this.relatedItems.filter((relatedItem) => relatedItem.id !== id);
            this.relatedItems = updatedPosts;
            this.relatedItemsUpdated.next([...this.relatedItems]);
        });
    }

    addRelatedItemPost(
        item_cd1: number,
        item_cd2: number,
        item_cd3: number,
        item_cd4: number,
        item_cd5: number,
        item_cd6: number,
        item_cd7: number,
        item_cd8: number,
        item_cd9: number,
        item_cd10: number,
        item_cd11: number,
        item_cd12: number,
        item_cd13: number,
        item_cd14: number,
        item_cd15: number,
        item_cd16: number,
        item_cd17: number,
        item_cd18: number,
        item_cd19: number,
        item_cd20: number,
    ) {
        const post = {
            item_cd1,
            item_cd2,
            item_cd3,
            item_cd4,
            item_cd5,
            item_cd6,
            item_cd7,
            item_cd8,
            item_cd9,
            item_cd10,
            item_cd11,
            item_cd12,
            item_cd13,
            item_cd14,
            item_cd15,
            item_cd16,
            item_cd17,
            item_cd18,
            item_cd19,
            item_cd20
        };

        this.http.post<{ post: RelatedItem; message: string }>(ITEM_BACKEND_URL, post).subscribe((responseData) => {
            this.relatedItems.push(post);
            this.relatedItemsUpdated.next([...this.relatedItems]);
            // this.router.navigate(['company-list']);
        });
    }


    getRelatedItem(id: number) {
        return this.http.get<{
            id: number,
            item_cd1: number,
            item_cd2: number,
            item_cd3: number,
            item_cd4: number,
            item_cd5: number,
            item_cd6: number,
            item_cd7: number,
            item_cd8: number,
            item_cd9: number,
            item_cd10: number,
            item_cd11: number,
            item_cd12: number,
            item_cd13: number,
            item_cd14: number,
            item_cd15: number,
            item_cd16: number,
            item_cd17: number,
            item_cd18: number,
            item_cd19: number,
            item_cd20: number,
            deleted_at: string
        }>(ITEM_BACKEND_URL + id);
    }

    updateRelatedItemPost(
        id: number,
        item_cd1: number,
        item_cd2: number,
        item_cd3: number,
        item_cd4: number,
        item_cd5: number,
        item_cd6: number,
        item_cd7: number,
        item_cd8: number,
        item_cd9: number,
        item_cd10: number,
        item_cd11: number,
        item_cd12: number,
        item_cd13: number,
        item_cd14: number,
        item_cd15: number,
        item_cd16: number,
        item_cd17: number,
        item_cd18: number,
        item_cd19: number,
        item_cd20: number,
    ) {
        const post: RelatedItem = {
            id,
            item_cd1,
            item_cd2,
            item_cd3,
            item_cd4,
            item_cd5,
            item_cd6,
            item_cd7,
            item_cd8,
            item_cd9,
            item_cd10,
            item_cd11,
            item_cd12,
            item_cd13,
            item_cd14,
            item_cd15,
            item_cd16,
            item_cd17,
            item_cd18,
            item_cd19,
            item_cd20,
        };
        this.http.put(ITEM_BACKEND_URL + id, post).subscribe(response => {
            this.router.navigate(['related-item-list']);
        });
    }


    // 削除済顧客を復活
    restoreRelatedItem(id) {
        this.http
            .delete<{ message: string }>(ITEM_BACKEND_URL + 'restore/' + id)
            .subscribe((responseData) => {
                this.router.navigate(['related-item-list']);
            });
    }
}
