import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../core/services/role-guard.service';
import { AuthGuard } from '../auth/auth.guard';
import { RelatedItemListComponent } from './related-item-list/related-item-list.component';
import { RelatedItemCreateComponent } from './related-item-create/related-item-create.component';


const routes: Routes = [
    { path: 'related-item-list', component: RelatedItemListComponent, canActivate: [AuthGuard]},
    { path: 'related-item-create', component: RelatedItemCreateComponent, canActivate: [RoleGuard], data: { role: ['admin', 'editor'] } },
    { path: 'related-item-edit/:id', component: RelatedItemCreateComponent, canActivate: [RoleGuard], data: { role: ['admin', 'editor'] } },
    // { path: 'item-detail/:cd', component: ItemDetailComponent, canActivate: [AuthGuard] },
];



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CommonModule, RouterModule.forChild(routes)
  ]
})
export class RelatedItemRoutingModule { }
